@charset "UTF-8";
/* card 공통 */
.card__content .service__main--title-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card__content-mask {
  display: none;
}
.card__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}
.card__item {
  position: relative;
  width: 400px;
  height: 540px;
  margin-right: 40px;
  flex-shrink: 0;
}
.card__item:last-child {
  margin-right: 0;
}
.card__item-head-caption {
  height: 100%;
  width: 50%;
}
.card__item .caption-desc {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  position: absolute;
  bottom: 10px;
  right: 0;
  opacity: 0;
  transition: opacity 0.5s;
}
@media screen and (min-width: 751px) {
  .card__item .caption-desc {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1025px) {
  .card__item .caption-desc {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1441px) {
  .card__item .caption-desc {
    font-size: 16px;
    line-height: 1.63;
  }
}
@media (max-width: 1025px) {
  .card__item .caption-desc {
    bottom: 0;
  }
}
.card__item-box {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transform: translate(0, -50%);
  padding: 34px;
  color: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.card__item-box:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  transition: 0.5s;
}
@media (min-width: 751px) {
  .card__item-box .card__item-info-main-text {
    font-size: 16px;
  }
}
.card__item-box-line {
  padding: 0;
}
.card__item-box-line .service__main--title-card {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.card__item-box-icon {
  position: relative;
  height: 100%;
}
.card__item-box-icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
}
.card__item-head {
  position: relative;
  min-height: 110px;
}
.card__item-head.no-caption .card__item-head-title {
  margin-right: 0;
}
.card__item-head-title {
  font-size: 34px;
  line-height: 1.29;
  font-weight: bold;
  font-weight: 600;
  margin-right: 46px;
  color: #fff;
  word-break: break-word;
  line-height: 1 !important;
}
@media screen and (min-width: 751px) {
  .card__item-head-title {
    font-size: 36px;
    line-height: 1;
  }
}
@media screen and (min-width: 1025px) {
  .card__item-head-title {
    font-size: 36px;
    line-height: 1;
  }
}
@media screen and (min-width: 1441px) {
  .card__item-head-title {
    font-size: 50px;
    line-height: normal;
  }
}
.card__item-head-caption {
  position: absolute;
  right: 0;
  top: 12px;
  font-size: 30px;
  line-height: 0.67;
  color: #fff;
  text-align: right;
  font-family: "Archivo";
}
.card__item-head2 {
  min-height: auto;
}
.card__item-head2-title {
  line-height: 1.24;
}
.card__item-contents {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s;
}
.card__item-contents-code {
  padding-top: 56px;
  padding-right: 50px;
}
.card__item-contents-code img {
  width: 100%;
}
.card__item-contents-text {
  padding-top: 40px;
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  line-height: 1.63;
  font-weight: 600;
}
@media screen and (min-width: 751px) {
  .card__item-contents-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1025px) {
  .card__item-contents-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1441px) {
  .card__item-contents-text {
    font-size: 16px;
    line-height: 1.63;
  }
}
.card__item-contents-text p {
  margin: 0;
  margin-top: 24px;
}
.card__item-contents-text p + p {
  margin-top: 8px;
}
.card__item-contents-list {
  padding-top: 40px;
}
.card__item-contents-list .item-contents__inner-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.card__item-contents-list .item-contents__inner-item {
  margin-bottom: 30px;
}
.card__item-contents-list .item-contents__inner-item:last-child {
  margin-bottom: 0;
}
.card__item-contents-list .item-contents__inner-item-title {
  font-size: 20px;
  font-weight: bold;
  font-weight: 600;
  margin: 0 0 3px;
}
@media screen and (min-width: 751px) {
  .card__item-contents-list .item-contents__inner-item-title {
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .card__item-contents-list .item-contents__inner-item-title {
    font-size: 16px;
  }
}
@media screen and (min-width: 1441px) {
  .card__item-contents-list .item-contents__inner-item-title {
    font-size: 20px;
  }
}
.card__item-contents-list .item-contents__inner-item-text {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 400;
}
@media screen and (min-width: 751px) {
  .card__item-contents-list .item-contents__inner-item-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1025px) {
  .card__item-contents-list .item-contents__inner-item-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1441px) {
  .card__item-contents-list .item-contents__inner-item-text {
    font-size: 16px;
    line-height: 1.63;
  }
}
.card__item-info {
  position: absolute;
  left: 34px;
  right: 34px;
  bottom: 34px;
  margin-top: auto;
  padding-right: 40px;
}
.card__item-info-main-profile {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.5s;
}
.card__item-info-main-profile .profile-img {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}
.card__item-info-main-profile .profile-img img {
  position: absolute;
}
.card__item-info-main-profile .profile-name {
  display: inline-block;
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 700;
  margin-left: 9px;
}
@media screen and (min-width: 751px) {
  .card__item-info-main-profile .profile-name {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1025px) {
  .card__item-info-main-profile .profile-name {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1441px) {
  .card__item-info-main-profile .profile-name {
    font-size: 16px;
    line-height: 1.63;
  }
}
.card__item-info-main-profile .profile-bg {
  display: block;
  width: 100%;
  height: 100%;
  background: #26ff00;
}
.card__item-info-main-profile .profile-bg.type-1 {
  background: #ff009d;
}
.card__item-info-main-profile .profile-bg.type-2 {
  background: #0049ff;
}
.card__item-info-main-title {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.5s;
}
@media screen and (min-width: 751px) {
  .card__item-info-main-title {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .card__item-info-main-title {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .card__item-info-main-title {
    font-size: 24px;
    line-height: 1.67;
  }
}
.card__item-info-main-text {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  line-height: 1.63;
}
@media screen and (min-width: 751px) {
  .card__item-info-main-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1025px) {
  .card__item-info-main-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1441px) {
  .card__item-info-main-text {
    font-size: 16px;
    line-height: 1.63;
  }
}
.card__item-info-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  display: block;
  background: url(/assets/img/card/expand-icon.png) no-repeat center/cover;
}
.card__item-blur .card__item-box-img-clone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: url(#sharpBlur);
  z-index: -1;
  opacity: 0;
  border-radius: 20px;
  transition: opacity 0.5s;
}
.card__item-blur .card__item-box::before {
  opacity: 0;
  transition: opacity 0.5s;
  background-blend-mode: multiply;
  background-color: rgba(106, 106, 106, 0.3);
}

.slide--service-code .card__item-info-main-profile {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1440px) {
  .card__list_v3 .card__item-head {
    min-height: auto;
  }
  .card__item {
    width: 303px;
    height: 408px;
    margin-right: 24px;
  }
  .card__item:last-child {
    margin-right: 0;
  }
  .card__item-box {
    padding: 24px;
  }
  .card__item-info {
    left: 24px;
    right: 24px;
    bottom: 24px;
  }
  .card__item-head {
    min-height: 72px;
  }
  .card__item-contents-text {
    padding-top: 24px;
  }
  .card__item-contents-text p {
    margin-top: 16px;
  }
  .card__item-contents-text p + p {
    margin-top: 4px;
  }
  .card__item-contents-code {
    padding: 28px 32px 0 0;
  }
  .card__item-contents-list {
    padding-top: 32px;
  }
  .card__item-contents-list .item-contents__inner-item {
    margin-bottom: 24px;
  }
  .card__item-contents-list .item-contents__inner-item:last-child {
    margin-bottom: 0;
  }
}
/* mobile */
@media (max-width: 750px) {
  .slide__wrap .slide__description br {
    display: none;
  }
}
/* 영문 */
[lang=en] {
  /* pc */
  /* mobile */
}
[lang=en] .item-contents__inner-item-text {
  font-family: "Archivo";
}
[lang=en] .card__item-head-caption .caption-desc {
  bottom: 20px;
  font-size: 14px;
}
@media (min-width: 751px) {
  [lang=en] {
    /* asset */
  }
  [lang=en] .card__item-contents-list {
    padding-top: 38px;
  }
  [lang=en] .card__item-contents-list .item-contents__inner-item-title {
    margin: 0 0 6px;
  }
  [lang=en] .slide--asset .card__item:first-child .card__item-contents-list .item-contents__inner-item {
    margin-bottom: 12px;
  }
}
@media (min-width: 751px) and (max-width: 1440px) {
  [lang=en] .card__item-contents-list {
    padding-top: 20px;
  }
  [lang=en] .card__item-info-main-text {
    font-size: 14px;
  }
}
@media (max-width: 750px) {
  [lang=en] .card__item-head-title {
    line-height: 1.1 !important;
  }
  [lang=en] .card__item-info-icon {
    width: 32px;
    height: 32px;
    margin-bottom: 5px;
  }
  [lang=en] .card__item-contents-list {
    padding-top: 29px;
  }
  [lang=en] .card__item-contents-list .item-contents__inner-item {
    margin-bottom: 25px;
  }
  [lang=en] .card__item-head-caption {
    right: 2px;
    top: 9px;
  }
  [lang=en] .card__item-head-caption .caption-desc {
    bottom: 12px;
    font-size: 12px;
  }
  [lang=en] .slide--asset .card__item-head-title {
    margin-right: 85px;
  }
  [lang=en] .slide--asset .slide__description br {
    display: none;
  }
  [lang=en] .slide--asset .card__item:first-child .card__item-contents-list {
    padding-top: 19px;
  }
  [lang=en] .slide--asset .card__item:first-child .card__item-contents-list .item-contents__inner-item-title {
    margin: 0 0 3px;
  }
  [lang=en] .slide--asset .card__item:first-child .card__item-contents-list .item-contents__inner-item {
    margin-bottom: 12px;
  }
  [lang=en] .slide--asset .card__item:first-child .card__item-contents-list .item-contents__inner-item-text {
    line-height: 1.25;
  }
}

/* card 배경 */
.card__list_v2 .card__item:nth-child(2) .profile-bg {
  background: #ff009d;
}
.card__list_v2 .card__item:nth-child(3) .profile-bg {
  background: #0049ff;
}
.card__list_v2 .card__item:nth-child(4) .profile-bg {
  background: #26ff00;
}
.card__list_v2 .item-bg-1 {
  background: url(/assets/img/card/image-sec-2-1.png) no-repeat top/cover;
}
.card__list_v2 .item-bg-2 {
  background: url(/assets/img/card/image-sec-2-2.png) no-repeat top/cover;
}
.card__list_v2 .item-bg-3 {
  background: url(/assets/img/card/image-sec-2-3.png) no-repeat top/cover;
}

.card__list_v3 .item-bg-1 {
  background: #e657af;
}
.card__list_v3 .item-bg-2 {
  background: #0049ff;
}
.card__list_v3 .item-bg-3 {
  background: url(/assets/img/card/image-sec-3-3.png) no-repeat top/cover;
}

.card__list_v4 .item-bg-1 {
  background: url(/assets/img/card/image-sec-4-1.png) no-repeat top/cover;
}
.card__list_v4 .item-bg-2 {
  background: #0049ff;
}
.card__list_v4 .item-bg-3 {
  background: #131415;
}
.card__list_v4 .item-bg-4 {
  background: #00bf41;
}

/* click 효과 */
.card__item-active .card__item-contents, .card__item--not-event .card__item-contents {
  opacity: 1;
}
.card__item-active .card__item-info-main-active .card__item-info-main-profile, .card__item--not-event .card__item-info-main-active .card__item-info-main-profile {
  opacity: 0;
}
.card__item-active .card__item-info-main-active .card__item-info-main-title, .card__item--not-event .card__item-info-main-active .card__item-info-main-title {
  opacity: 1;
}
.card__item-active .card__item-info-icon, .card__item--not-event .card__item-info-icon {
  background: url(/assets/img/card/contract-icon.png) no-repeat center/cover;
}
.card__item-active .card__item-info-main-title, .card__item--not-event .card__item-info-main-title {
  opacity: 0;
}
.card__item-active .caption-desc, .card__item--not-event .caption-desc {
  opacity: 1;
}
.card__item-active.card__item-blur .card__item-box-img-clone, .card__item--not-event.card__item-blur .card__item-box-img-clone {
  opacity: 1;
}
.card__item-active.card__item-blur .card__item-box::before, .card__item--not-event.card__item-blur .card__item-box::before {
  opacity: 1;
}

/*  mobile */
@media (max-width: 750px) {
  /* card 공통 */
  .card__content {
    text-align: center;
  }
  .card__item {
    width: calc(min(var(--window-width), 430) * 1px - 48px);
    height: calc((min(var(--window-width), 430) * 1px - 48px) * 1.32);
  }
  .card .card__list_v2, .card .card__list_v3, .card .card__list_v4 {
    width: 100%;
  }

  .card__item:last-child {
    margin-right: 0;
  }
  .card__item-box {
    padding: 26px;
    text-align: left;
  }
}
@media (max-width: 750px) and (max-width: 360px) {
  .card__item-box {
    padding: 15px;
  }
}
@media (max-width: 750px) {
  .card__item-box-icon img {
    width: 98px;
  }
}
@media (max-width: 750px) {
  .card__item-head {
    min-height: 88px;
  }
  .card__item-head-title {
    font-size: 40px;
  }
  .card__item-head-caption {
    right: 3px;
    top: 9px;
    font-size: 20px;
    line-height: 1;
  }
  .card__item-head-caption .caption-desc {
    bottom: 4px;
  }
}
@media (max-width: 750px) {
  .card__item-head2 {
    min-height: auto;
  }
  .card__item-head2-title {
    line-height: 1.24;
  }
}
@media (max-width: 750px) {
  .card__item-contents-code {
    padding-top: 27px;
    padding-right: 0;
  }
  .card__item-contents-text {
    padding-top: 30px;
  }
  [lang=en] .card__item-contents-text {
    padding-top: 20px;
    font-size: 14px;
  }

  .card__item-contents-text br {
    display: none;
  }
  .card__item-contents-text p {
    font-size: 14px;
    line-height: 1.71;
  }
  .card__item-contents-text p + p {
    margin-top: 4px;
  }
  .card__item-contents-list {
    padding-top: 28px;
  }
  .card__item-contents-list .item-contents__inner-item {
    margin-bottom: 20px;
  }
  .card__item-contents-list .item-contents__inner-item-text {
    font-size: 16px;
  }
}
@media (max-width: 750px) {
  .card__item-info {
    left: 26px;
    right: 26px;
    bottom: 26px;
    padding-right: 40px;
  }
}
@media (max-width: 750px) and (max-width: 360px) {
  .card__item-info {
    left: 15px;
    right: 15px;
    bottom: 15px;
  }
}
@media (max-width: 750px) {
  .card__item-info-main-profile {
    opacity: 1;
    transition: opacity 0.5s;
  }
  .card__item-info-main-profile .profile-img {
    width: 30px;
    height: 30px;
  }
  .card__item-info-main-profile .profile-name {
    margin-left: 8px;
  }
  .card__item-info-main-title {
    opacity: 0;
    transition: opacity 0.5s;
    font-size: 24px;
    font-weight: 600;
  }
  .card__item-info-main-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.63;
    transform: translateY(-3px);
  }
}
@media (max-width: 750px) {
  .card__item-info-icon {
    width: 34px;
    height: 34px;
  }
}
@media (max-width: 750px) {
  .card__list_v2 {
    display: block;
  }
  .card__list_v2 .card__item {
    margin-bottom: 32px;
  }
  .card__list_v2 .card__item:last-child {
    margin-bottom: 0px;
  }
  .card__list_v2 .card__item:first-child {
    display: none;
  }
  .card__list_v3 {
    display: block;
  }
  .card__list_v3 .card__item + .card__item {
    margin-top: 32px;
  }
}
.card__list_v1 .card__item-box {
  border: solid 2px #fff;
}
.card__list_v1 .card__item-box.line-color {
  border: 2px solid transparent;
  padding: 0;
  background-image: linear-gradient(#000, #000), linear-gradient(315deg, #e657af, #0049ff, #43cf2e);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
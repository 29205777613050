/* header */
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	color: #fff;
	z-index: 100;
	transform: translateY(-100%);
	transition: transform 0.3s, color 0.5s;

	@at-root .use--keyboard #{&},
		&.show {
		transform: translateY(0);
	}

	a {
		color: #fff;
		text-decoration: none;
		transition: color 0.5s;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		list-style: none;
	}

	svg path {
		transition: stroke 0.5s;
	}

	&__content {
		display: flex;
		justify-content: space-between;
		// align-items: center;
	}

	&__container {
		margin-left: auto;
		margin-right: auto;
		padding: 0 100px;
	}

	&__logo {
		width: 100px;
		&--link {
			display: inline-block;
			padding: 17px 0;
			img {
				width: 100%;
				-webkit-filter: brightness(0) invert(1);
				filter: brightness(0) invert(1);
				transition: filter 0.5s;
			}
		}
	}

	&__nav {
		position: relative;
		display: flex;
		align-items: center;
		&--link {
			display: inline-block;
			padding: 23px 25px;
			font-size: 18px;
			font-weight: 600;
			line-height: 1;
		}
	}

	&__lang {
		position: relative;
		cursor: pointer;
		&--title {
			display: flex;
			align-items: center;
			padding: 20px 0 18px 25px;
		}
		&--text {
			display: inline-block;
			margin: 0 5px;
			font-size: 18px;
			font-weight: 600;
			line-height: 1;
		}
		&--subnav {
			position: absolute;
			right: 0;
			top: 53px;
			opacity: 0;
			visibility: hidden;
			text-align: center;
			background-color: #000;
			border: solid 1px #5a5a5a;
			border-radius: 6px;
			transition: all 0.3s;
			&-item {
				.header__lang--subnav-link {
					display: block;
					padding: 2px 14px 4px 13px;
					font-size: 18px;
					line-height: 1.33;
					font-weight: 600;
					span {
						opacity: 0.2;
					}
					&.active {
						span {
							opacity: 1;
						}
					}
				}
			}
			&-item:first-child {
				.header__lang--subnav-link {
					padding-top: 6px;
					padding-bottom: 2px;
				}
			}

			/* show */
			@at-root .use--keyboard .header__lang:focus .header__lang--subnav,
				&.show {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__hamburger {
		display: none;
		box-sizing: border-box;
		padding: 21px 3px;
		z-index: 120;
		&--inner {
			position: relative;
			width: 24px;
			height: 14px;
		}
		&--item {
			position: absolute;
			right: 0;
			width: 100%;
			height: 2px;
			background-color: #fff;
			transition: all 0.3s;
			&.top {
				top: 0;
				width: 12px;
			}
			&.middle {
				top: 50%;
				transform: translateY(-50%);
			}
			&.bottom {
				bottom: 0;
			}
		}

		/* show */
		&.show {
			.header__hamburger--item {
				background-color: #000 !important;
			}
			.top {
				width: 100%;
				top: 50%;
				transform: rotate(135deg);
			}
			.middle {
				opacity: 0;
			}
			.bottom {
				top: 50%;
				transform: rotate(-135deg);
			}
		}
	}

	&__menu {
		display: flex;
		position: fixed;
		top: calc(var(--full-height, 100vh) * -1);
		left: 0;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: calc(var(--full-height, 100vh) * 1);
		padding: 70px 24px 24px !important;
		background-color: #fff;
		z-index: 110;
		box-sizing: border-box;
		overflow-y: scroll;
		opacity: 0;
		visibility: hidden;
		transition: top 0.4s, opacity 0.4s, visibility 0.4s;
		& > * {
			opacity: 0;
		}
		a {
			display: inline-block;
			color: #000;
			font-size: 32px;
			font-weight: 600;
			line-height: 1.31;
			padding: 14px 0;
		}
		.header__lang--mo {
			&-item {
				display: inline;
				&:not(:last-child) {
					margin-right: 20px;
				}
			}
			&-link {
				color: #c0c0c0 !important;
				&.active {
					color: #000 !important;
				}
			}
		}
		&--logo {
			font-size: 22px;
			font-weight: 600;
			line-height: 1.36;
			color: #000;
		}
	}

	/* header--dark */
	&.header--dark {
		color: #000;
		a {
			color: #000;
		}

		img {
			-webkit-filter: brightness(0) invert(0);
			filter: brightness(0) invert(0);
		}

		.header__lang {
			&--subnav {
				background-color: #fff;
				border-color: #c5c5c5;
			}
			svg path {
				stroke: #000;
			}
		}
	}

	/* mobile */
	@media (max-width: 750px) {
		transform: translateY(-100%);

		&__container {
			padding: 0 21px 0 24px;
		}

		&__logo {
			width: 50px;
			&--link {
				padding: 13px 0;
			}
		}

		&__nav {
			display: none;
		}

		&__hamburger {
			display: block;
		}

		&__menu {
			/* show */
			&.show {
				top: 0;
				opacity: 1;
				visibility: visible;
				& > * {
					opacity: 1;
					transition: opacity 0.45s 0.4s;
				}
			}
		}

		/* header--dark */
		&.header--dark {
			.header__hamburger--item {
				background-color: #000;
			}

			.header__hamburger.show {
				.header__hamburger--item {
					background-color: #fff;
				}
			}
		}
	}

	/* 1440 */
	@media (min-width: 1025px) and (max-width: 1440px) {
		&__container {
			padding: 0 72px;
		}
	}

	/* 1024 */
	@media (min-width: 751px) and (max-width: 1024px) {
		&__container {
			padding: 0 60px;
		}

		&__menu {
			padding-left: 60px !important;
			padding-right: 60px !important;
		}
	}
}

/* top__btn */
.top__btn {
	cursor: pointer;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 100px;
	bottom: 40px;
	z-index: 50;
	background-color: #111;
	width: 50px;
	height: 50px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0.3s;

	svg {
		width: 35px;
		height: 30px;
	}

	&.show {
		opacity: 1;
		visibility: visible;

		&.video-top-btn {
			opacity: 0;
			visibility: hidden;
		}
	}

	&.stop {
		position: absolute;
	}

	/* 1440 */
	@media (min-width: 1025px) and (max-width: 1440px) {
		right: 72px;
	}

	/* 1024 */
	@media (min-width: 751px) and (max-width: 1024px) {
		right: 60px;
	}

	/* mobile */
	@media (max-width: 750px) {
		right: 24px;
		bottom: 24px;
	}
}

html[data-scradar-scroll="1"] {
	.top__btn {
		opacity: 0;
		visibility: hidden;

		&.stop {
			opacity: 1;
			visibility: visible;
		}
	}
}

/* footer */
.footer {
	background: #000;
	position: relative;
	color: #fff;
	padding: 0 100px;

	&__container {
		display: flex;
		justify-content: space-between;
		padding: 58px 0;
	}

	&__logo {
		img {
			width: 193px;
			display: block;
		}

		&-mobile {
			display: none;
		}
	}

	&__info {
		&-link {
			a {
				font-size: 16px;
				font-weight: 600;
				line-height: 1.38;
				color: #fff;
				text-decoration: none;
				display: inline-block;
			}
		}

		&-text {
			font-size: 14px;
		}

		&-address {
			font-weight: 400;
			line-height: 1;
		}

		&-copyright {
			margin-top: 10px;
			font-weight: 300;
			line-height: 1;
			text-transform: uppercase;
		}
	}
}

/* 1440 */
@media (min-width: 1025px) and (max-width: 1440px) {
	.footer {
		padding: 0 72px;
	}
}

/* 1024 */
@media (min-width: 751px) and (max-width: 1024px) {
	.footer {
		padding: 0 60px;
	}
}

/* mobile */
@media (max-width: 750px) {
	.footer {
		padding: 0 24px;
	}
}

@media (max-width: 900px) {
	.footer {
		&__container {
			display: block;
			padding: 36px 0;
		}

		&__logo {
			img {
				width: 154px;
			}
			&-mobile {
				display: block;
			}

			&-pc {
				display: none;
			}
		}

		&__info {
			padding-top: 44px;

			&-address {
				line-height: 1.43;
			}

			&-copyright {
				line-height: 1.43;
				span {
					display: inline-block;
				}
			}
		}
	}
}

@charset "UTF-8";
@keyframes showcase-intro-background {
  0% {
    opacity: 0;
  }
  7% {
    opacity: 0.6;
  }
  14% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes showcase-intro-left {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  7% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  14% {
    transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
  20% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}
@keyframes showcase-intro-left-en {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  7% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  14% {
    transform: translate3d(50%, 0, 0);
    opacity: 1;
  }
  20% {
    transform: translate3d(50%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}
@keyframes showcase-intro-center {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  7% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  14% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  20% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}
@keyframes showcase-intro-right {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  7% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  14% {
    transform: translate3d(-100%, 0, 0);
    opacity: 1;
  }
  20% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}
@keyframes showcase-intro-right-en {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  7% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  14% {
    transform: translate3d(-50%, 0, 0);
    opacity: 1;
  }
  20% {
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}
.showcase {
  position: relative;
  height: calc(var(--full-height, 100vh) * 7);
  /* mobile */
}
.showcase__content {
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--full-height, 100vh);
}
.showcase__img {
  position: relative;
  height: 100%;
}
.showcase__img picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.showcase__img picture:nth-child(1):after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: max((var(--progress-full) - 0.6) * 2, 0);
}
.showcase__img picture:nth-child(2) {
  bottom: calc(var(--full-height, 100vh) * min((var(--progress-full) - 0.4) * 5, 1));
}
.showcase__img picture:nth-child(3) {
  bottom: calc(var(--full-height, 100vh) * min((var(--progress-full) - 0.2) * 5, 1));
}
.showcase__img picture:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.showcase__img picture img {
  position: absolute;
  width: 100vw;
  height: var(--full-height, 100vh);
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
}
.showcase__intro {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.showcase__intro:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  animation: showcase-intro-background 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.showcase__intro-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  line-height: 1.2;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.2 !important;
  text-align: center;
  color: #fff;
}
@media screen and (min-width: 751px) {
  .showcase__intro-inner {
    font-size: 50px;
    line-height: 1;
  }
}
@media screen and (min-width: 1025px) {
  .showcase__intro-inner {
    font-size: 100px;
    line-height: 1;
  }
}
.showcase__intro--text:nth-child(1) {
  animation: showcase-intro-left 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.showcase__intro--text:nth-child(2) {
  animation: showcase-intro-center 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.showcase__intro--text:nth-child(3) {
  animation: showcase-intro-right 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.showcase__description {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 600px;
  padding: 32px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.67;
  color: #fff;
  transform: translate(-50%, -50%);
  opacity: calc((var(--progress-full) - 0.6) / 0.4);
}
@media (max-width: 1000px) {
  .showcase__description br {
    display: none;
  }
}
@media (max-width: 750px) {
  .showcase__description {
    padding: 24px;
    width: 100%;
    font-size: 20px;
  }
}

/* 영문 */
[lang=en] .showcase {
  /* mobile */
}
[lang=en] .showcase__intro--text:nth-child(1) {
  animation: showcase-intro-left-en 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
[lang=en] .showcase__intro--text:nth-child(3) {
  animation: showcase-intro-right-en 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
[lang=en] .showcase__description {
  width: 100%;
  max-width: 810px;
}
@media (max-width: 750px) {
  [lang=en] .showcase__intro-inner {
    font-size: 40px;
    line-height: 1.3 !important;
  }
}
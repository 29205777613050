@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'base/reset';
@import 'base/typography';
@import 'base/layout';
@import 'base/components';

.use--keyboard {
  *:focus {
    outline: 2px #0656c3 solid !important;
  }
}
// .slide {
//   $slide: '.slide';

//   height: max(calc(var(--full-height, 100vh) + var(--scroll-width) * 1px), var(--full-height, 100vh));
//   &[data-scradar-start='1'][data-scradar-end='1'] {
//     .slide__wrap {
//       position: fixed;
//     }
//   }
//   &--asset {
//     height: auto;
//     .slide__wrap {
//       height: auto;
//       overflow: visible;
//     }
//     .slide__inner, .slide__body {
//       transform: none;
//     }
//     &[data-scradar-start="1"][data-scradar-end="1"] .slide__wrap {
//       position: relative;
//     }
//   }
//   /* mobile */
//   &__wrap {
//     position: sticky;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: var(--full-height, 100vh);
//     padding: 0;
//     overflow: visible;
//     box-sizing: border-box;
//   }
//   &__head {
//     padding: 112px 24px 36px;
//     .title {
//       @include font-size(72px);
//       @at-root body.dark & {
//         color: #fff;
//         transition: color 0.5s;
//       }
//     }
//   }
//   &__body {
//     display: flex;
//     align-items: center;
//     flex-wrap: nowrap;
//     padding: 0 24px;
//     overflow: hidden;
//     .slide__item {
      
//     }
//   }
//   &__bottom {
//     padding: 0 24px;
//   }
//   &__description {
//     @include font-size(24px);
//   }

//   // &-sub {
//   //   height: auto;
//   //   &[data-scradar-start="1"][data-scradar-end="1"] {
//   //     .slide__wrap {
//   //       position: relative;
//   //     }
//   //   }
//   //   #{$slide}__wrap {
//   //     position: relative;
//   //     height: auto;
//   //   }
//   //   #{$slide}__inner {
//   //     transition: none;
//   //   }
//   //   &__wrap {
//   //     position: relative;
//   //     height: max(calc(var(--full-height, 100vh) + var(--scroll-width) * 1px), var(--full-height, 100vh));
//   //   }
//   //   &__inner {
//   //     position: sticky;
//   //     top: 0;
//   //     left: 0;
//   //     width: 100%;
//   //     height: var(--full-height, 100vh);
//   //     overflow: hidden;
//   //     #{$slide}__body {
//   //       .slide__item {
//   //         transform: translate3d(calc((var(--scroll-width) * 1px + 48px) * var(--progress-full) * -1), 0, 0);
//   //       }
//   //     }
//   //   }
//   // }
    
//   @media (min-width: 751px) {
//     height: max(calc(var(--full-height, 100vh) + var(--scroll-width) * 1px), var(--full-height, 100vh));
    
//     &--asset {
//       height: max(calc(var(--full-height, 100vh) + var(--scroll-width) * 1px), var(--full-height, 100vh));
//       .slide__wrap {
//         height: var(--full-height, 100vh);
//         overflow: hidden;
//       }
//       .slide__inner {
//         transform: translate3d(calc((var(--scroll-width) * 1px + 120px) * var(--progress-full) * -1), 0, 0);
//       }
//       &[data-scradar-start='1'][data-scradar-end='1'] {
//         .slide__wrap {
//           position: fixed;
//         }
//       }
//     }
//     &[data-scradar-start='1'][data-scradar-end='1'] {
//       .slide__wrap {
//         position: fixed;
//       }
//     }
//     &__body {
//       display: block;
//       width: calc(var(--body-width) * 1px); 
//       height: auto;
//       padding: 0;
//       transform: none;
//       overflow: visible;
//       .slide__item {
//         transform: none;
//       }
//     }
//     @at-root #{$slide} {
//       * + #{$slide}__body {
//         padding-left: 80px;
//       }
//     }
//     &__title {
//       padding: 0;
//     }
//     &__wrap {
//       position: sticky;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: var(--full-height, 100vh);
//       padding: 0 60px;
//       overflow: hidden;
//     }
//     &__inner {
//       display: flex;
//       align-items: center;
//       flex-wrap: nowrap;
//       height: var(--full-height, 100vh);
//       transform: translate3d(calc((var(--scroll-width) * 1px + 120px) * var(--progress-full) * -1), 0, 0);
//     }
//     &__item,
//     &__head {
//       flex-shrink: 0;
//       padding: 0;
//       & + .slide__item {
//         padding-left: 80px;
//       }
//     }
//   }

//   @media (min-width: 1025px) {
//     &__wrap {
//       padding: 0 72px;
//     }
//     &__inner {
//       transform: translate3d(calc((var(--scroll-width) * 1px + 144px) * var(--progress-full) * -1), 0, 0);
//     }
//     @at-root #{$slide} {
//       * + #{$slide}__body {
//         padding-left: 104px;
//       }
//     }
//     &__item,
//     &__head {
//       & + .slide__item {
//         padding-left: 104px;
//       }
//     }
//   }

//   @media (min-width: 1441px) {
//     &__wrap {
//       padding: 0 100px;
//     }
//     &__inner {
//       transform: translate3d(calc((var(--scroll-width) * 1px + 200px) * var(--progress-full) * -1), 0, 0);
//     }
//     @at-root #{$slide} {
//       * + #{$slide}__body {
//         padding-left: 160px;
//       }
//     }
//     &__item,
//     &__head {
//       & + .slide__item {
//         padding-left: 160px;
//       }
//     }
//   }
// }

.slide {
  position: relative;
  height: max(calc(var(--full-height, 100vh) + var(--scroll-width) * 1px), var(--full-height, 100vh));
  &[data-scradar-start="1"][data-scradar-end="1"] {
    .slide__wrap {
      position: fixed;
    }
  }
  &__wrap {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--full-height, 100vh);
    padding: 0 100px;
    overflow: hidden;
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: var(--full-height, 100vh);
    transform: translate3d(calc((var(--scroll-width) * 1px + var(--space) * 1px) * var(--progress-full) * -1), 0, 0);
    
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__head {
    flex-shrink: 0;
    padding: 0 160px 0 0;
    .title {
      @include font-size(72px);
      @at-root body.dark & {
        color: #fff;
        transition: color 0.5s;
      }
    }
  }
  &__body {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  
  &__item {
    flex-shrink: 0;
  }
  &__bottom {
    flex-shrink: 0;
    padding: 0 60px 0 160px;
    .slide__description {
      @include font-size(24px);
    }
  }

  &-sub {
    &__wrap {
      flex-shrink: 0;
    }
    &__inner {
      display: flex;
      align-items: center;
    }
  }
  
  @media (max-width: 1440px) {
    &__wrap {
      padding: 0 72px;
    }
    &__inner {
      
    }
    &__head {
      padding: 0 104px 0 0;
    }
    &__container {
      
    }
    &__body {

    }
    &__item {
  
    }
    &__bottom {
      padding: 0 30px 0 104px;
    }
  }
  @media (max-width: 1024px) {
    &__wrap {
      padding: 0 60px;
    }
    &__inner {
    }
    &__head {
      padding: 0 80px 0 0;
    }
    &__container {
      
    }
    &__body {

    }
    &__item {
  
    }
    &__bottom {
      padding: 0 20px 0 80px;
    }
  }
  @media (max-width: 750px) {
    height: calc(var(--full-height, 100vh) * 5);
    &__wrap {
      padding: 0;
      overflow: visible;
    }
    &__inner {
      display: block;
    }
    &__head {
      padding: 13.7931vh 24px 36px;
    }
    &__body {
      padding: 0 24px;
      overflow: hidden;
    }
    &__item {
  
    }
    &__bottom {
      padding: 0 24px;
      .slide__description {
        font-size: 20px;
        line-height: 1.7;
      }
    }

    &-sub {
      height: auto;
      &[data-scradar-start="1"][data-scradar-end="1"] {
        .slide__wrap {
          position: relative;
        }
      }
      .slide__wrap {
        position: relative;
        height: auto;
      }
      .slide__inner {
        height: auto;
        transform: none;
      }
      &__wrap {
        position: relative;
        height: max(calc(var(--full-height, 100vh) + var(--scroll-width) * 1px), var(--full-height, 100vh));
      }
      &__inner {
        display: block;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--full-height, 100vh);
        overflow: hidden;
        .slide__body {
          & > * {
            transform: translate3d(calc((var(--scroll-width) * 1px + 48px) * var(--progress-full) * -1), 0, 0);
          }
        }
      }
    }

    &--asset {
      height: auto;
      .slide__wrap {
        height: auto;
        overflow: visible;
      }
      .slide__inner {
        height: auto;  
        transform: none;
      }
      .slide__item {
        margin: 0 auto;
        // padding: 0 24px;
      }
      &[data-scradar-start="1"][data-scradar-end="1"] .slide__wrap {
        position: relative;
      }
    }
    &--possibility {
      .slide {
        &__head {
          padding: 13.7931vh 24px 72px;
        }
        &__bottom {
          padding-top: 40px;
        }
      }
    }
  }
}

@media (max-width: 375px) and (min-aspect-ratio: 1/2) {
  .slide {
    &:not(.slide--asset) {
      .slide__head {
        padding: 10.8374vh 24px 28px;
        .title {
          font-size: 28px;
          line-height: 1.36;
          br {
            display: none;
          }
          br ~ br{
            display: inline;
          }
        }
      }
    }
  }
  
  // &__bottom {
  //   .slide__description {
  //     br {
  //       display: none;
  //     }
  //     font-size: 28px;
  //     line-height: 1.36;
  //   }
  // }
}

.swiper {
  &__wrapper {}
  &__slide {}
  &__navi {
    display: none;
  }
  @media (max-width: 750px) {
    margin: 0 -24px;
    padding: 0 24px;
    @at-root .creator & {
      padding: 0 39px;
    }
    &__navi {
      display: block;
      margin-top: 32px;
      text-align: right;
      .navi__button {
        width: 28px;
        height: 28px;
        padding: 0;
        border: 0 none;
        background-size: 100%;
        background-repeat: no-repeat;
        &--prev {
          background-image: url('/assets/img/service/icon-nav-prev-black-on.png');
          &.navi__button--disable {
            background-image: url('/assets/img/service/icon-nav-prev-black-off.png');
          }
        }
        &--next {
          margin-left: 16px;
          background-image: url('/assets/img/service/icon-nav-next-black-on.png');
          &.navi__button--disable {
            background-image: url('/assets/img/service/icon-nav-next-black-off.png');
          }
        }
      }
      &--white {
        .navi__button--prev {
          background-image: url('/assets/img/service/icon-nav-prev-white-on.png');
          &.navi__button--disable {
            background-image: url('/assets/img/service/icon-nav-prev-white-off.png');
          }
        }
        .navi__button--next {
          margin-left: 16px;
          background-image: url('/assets/img/service/icon-nav-next-white-on.png');
          &.navi__button--disable {
            background-image: url('/assets/img/service/icon-nav-next-white-off.png');
          }
        }
      }
    }
  }
}

/* 영문 */

[lang="en"]  {
  /* mobile */
  @media (max-width: 750px) {
    .slide__title {
      font-size: 40px;
      line-height: 1.35;
    }
  }
}
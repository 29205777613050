@import '../abstracts/mixins';

.slide--asset {
  .card__item-head-title {
    line-height: 1.24 !important;
  }
  .slide__item.scradar {
    @media (max-width: 750px) {
      position: relative;
      height: calc(var(--full-height, 100vh) * 3);
      margin-top: calc(var(--full-height, 100vh) / -2);
      .slide__sticky {
        display: flex;
        align-items: center;
        position: sticky;
        top: 0;
        height: var(--full-height, 100vh);
        opacity: calc(var(--progress-full) * 2);
      }
    }
  }

  /* pc */
  @media (min-width: 751px) {
    &[data-scradar-start="1"][data-scradar-end="1"]{
      .down {
        opacity: 1;
        visibility: visible;
      }
    }

    &[data-full-step="3"],
    &[data-full-step="4"] {
      .down__title--item {
        opacity: 0;
        &:nth-child(2) {
          opacity: 1;
        }
      }
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    .card__item-head-title {
      line-height: 1.1 !important;
    }

    .card__content {
      position: relative;
      z-index: 2;
    }

    .down__title--item {
      opacity: 0;
      &:nth-child(2) {
        opacity: 1;
      }
    }

    &[data-visible-step="1"] {
      .down {
        opacity: 1;
        visibility: visible;
      }
    }

    &[data-full-step="2"],
    &[data-full-step="3"],
    &[data-full-step="4"] {
      .down {
        opacity: 0;
        visibility: hidden;
      }
    }

    &[data-full-step="0"]{
      .down__title--item {
        opacity: 1;
        &:nth-child(2) {
          opacity: 0;
        }
      }
    }
  }
}

/* down */
.down {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 40;
  padding: 10px 0 calc(var(--full-height, 100vh) * 0.065);
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(249, 249, 249, 0), #fff);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  &__arrow {
    &--item {
      display: block;
      width: 27px; 
      height: 27px;
      transform: rotate(45deg);
      border-style: solid;
      border-width: 6px;
      border-image: linear-gradient(60deg,#e657af 60%,#0049ff 160%);
      border-image-slice: 1;
      animation: mouse-scroll 1s infinite alternate;
      &:nth-child(1) {
        border-image: linear-gradient(60deg,#e657af 60%,#0049ff 160%);
        border-image-slice: 1;
        animation-delay: 0.1s; 
      }
      &:nth-child(2) {
        border-image: linear-gradient(60deg,#e657af 20%,#0049ff 145%);
        border-image-slice: 1;
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        border-image: linear-gradient(60deg,#e657af,#0049ff 130%);
        border-image-slice: 1;
        animation-delay: 0.3s; 
      }
      &:nth-child(4) {
        border-image: linear-gradient(50deg,#e657af,#0049ff 100%);
        border-image-slice: 1;
        animation-delay: 0.4s; 
      }
      &:nth-child(5) {
        border-image: linear-gradient(50deg,#e657af,#0049ff 80%);
        border-image-slice: 1;
        animation-delay: 0.5s; 
      }
      &:nth-child(6) {
        border-image: linear-gradient(50deg,#0049ff 45%,#43cf2e 135%);
        border-image-slice: 1;
        animation-delay: 0.6s; 
      }
      &:nth-child(7) {
        border-image: linear-gradient(51deg,#0049ff,#43cf2e 75%);
        border-image-slice: 1;
        animation-delay: 0.7s; 
      }
    }
    @keyframes mouse-scroll {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &__title {
    position: relative;
    margin-top: 15px;
    &--item {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-46.5%);
      @include font-size(24px);
      font-weight: 600;
      transition: opacity 0.3s;
      &:not(:first-child) {
        opacity: 0;
      }
    }
  }

  /* pc */
  @media (min-width: 751px) {
    &__arrow {
      display: flex;
      justify-content: center;
      &--item{
        border-width: 8px;
        border-left: 0;
        border-bottom: 0;
        margin-right: -6px;
      }
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    padding: 10px 0 46px;
    &__arrow {
      &--item {
        width: 18px;
        height: 18px;
        margin: auto;
        border-top: 0;
        border-left: 0;
        margin-bottom: -3px;
        &:nth-child(n+4) {
          display: none;
        }
        &:nth-child(1) {
          border-image: linear-gradient(125deg,#e657af 60%,#0049ff 140%);
          border-image-slice: 1;
        }
        &:nth-child(2) {
          border-image: linear-gradient(125deg,#e657af 10%,#0049ff);
          border-image-slice: 1;
        }
        &:nth-child(3) {
          border-image: linear-gradient(125deg,#0049ff 60%,#43cf2e);
          border-image-slice: 1;
        }
      }
    }
    .down__title {
      &--item {
        transform: translateX(-50%);
      }
    }
  }
}
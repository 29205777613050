@import '../abstracts/mixins';

/* feature */
.feature {
  position: relative;
  overflow: hidden;

  &__content {
    position: relative;
    height: 175px;
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      height: calc(100% - 2px);
      width: 100%;
      backdrop-filter: blur(50px);
      outline: 1px solid #000;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &__item {
    padding: 45px 0;
    text-align: center;
    color: #fff;
    &:nth-child(1) {
      background-color: #e657af;
    }
    &:nth-child(2) {
      background-color: #0049ff;
    }
    &:nth-child(3) {
      background-color: #00bf41;
    }
    &--inner{
      position: relative;
      z-index: 10;
      opacity: calc((var(--progress-visible) - 0.3) / 0.15);
      transition: opacity 0.3s;
    }
    &--title {
      font-family: 'Archivo';
      font-size: 60px;
      font-weight: 600;
      line-height: 1;
    }
    &--subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.63;
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0 100px;
    /* pc */
    @media (min-width:751px) and (max-width: 1024px) {
      padding: 0 60px;
    }
    transform: translateY(-50%);
    text-align: center;
    @include font-size(40px);
    font-weight: 600;
    line-height: 1.25;
    color: #fff;
    opacity: calc((var(--progress-visible) - 0.52) / 0.15);
  }
  
  &[data-visible-step="1"] {
    .feature__content:after {
      opacity: 1;
    }
    .feature__item--inner {
      opacity: 0;
    }
  }

  /* pc */
  @media (min-width: 751px) {
    &__content {
      display: flex;
    }

    &__item {
      width: 33.333%;
      &:nth-child(-n+2) {
        transform: translateX(max(min(calc(200% * (min(var(--progress-visible) - 0.3, 1))), 0.0001%), -50%));
      }
      &:nth-child(3) {
        transform: translateX(max(min(calc(-200% * (min(var(--progress-visible) - 0.3, 0))), 50%), 0.0001%));
      }
      &--title {
        font-size: 30px;
      }
    }

    &__title {
      br {
        display: none;
      }
    }
  }
  @media (min-width: 1025px) {
    &__item {
      &--title {
        font-size: 45px;
      }
    }
  }
  @media (min-width: 1441px) {
    &__item {
      &--title {
        font-size: 60px;
      }
      &--subtitle {
        font-size: 16px;
      }
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    &__content {
      height: 360px;
      &:after {
        outline: none;
      }
    }

    &__item {
      padding: 0;
      position: relative;
      height: 25px;
      height: min(calc(90% * max(var(--progress-visible) - 0.045, 0)), 33.333%);
      &:nth-child(1) {
        height: 33.333%;
      }
      &--inner {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
        opacity: calc((var(--progress-visible) - 0.3) / 0.1);
      }
      &--title {
        font-size: 40px;
        line-height: 1.1;
      }
      &--subtitle {
        // line-height: 1.63;
      }
    }

    &__title {
      padding: 0 24px;
      font-size: 24px;
      line-height: 1.42;
      opacity: calc((var(--progress-visible) - 0.52) / 0.1);
    }
  }
}
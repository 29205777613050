@charset "UTF-8";
@keyframes intro-description-1 {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  24% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes intro-description-2 {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 0;
  }
  24% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  41% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  83% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes intro-description-3 {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  41% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  74% {
    opacity: 0;
  }
  83% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes intro-description-4 {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  74% {
    opacity: 0;
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes intro-scroll-down {
  0% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  83% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.intro {
  position: relative;
  height: calc(var(--full-height, 100vh) * 8);
  /* tablet */
  /* mobile */
}
.intro:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: calc((var(--progress-full) - 0.01) / 0.16);
}
.intro__video {
  position: fixed;
  top: 0;
  width: 100vw;
  height: var(--full-height, 100vh);
  mix-blend-mode: multiply;
  z-index: -1;
  background: #000;
}
.intro__video .poster {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.intro__video .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.intro__video video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.intro__video.loaded .poster {
  opacity: 0;
}
.intro__description {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--full-height, 100vh) * 1);
}
.intro__description--text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  padding: 0 32px;
  font-size: 36px;
  line-height: 1.33;
  font-weight: bold;
  font-weight: 700;
  line-height: 1.35;
  color: #fff;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 751px) {
  .intro__description--text {
    font-size: 26px;
    line-height: 1.35;
  }
}
@media screen and (min-width: 1025px) {
  .intro__description--text {
    font-size: 52px;
    line-height: 1.35;
  }
}
.intro__description--text:nth-child(1) {
  animation: intro-description-1 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.intro__description--text:nth-child(2) {
  animation: intro-description-2 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.intro__description--text:nth-child(3) {
  animation: intro-description-3 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.intro__description--text:nth-child(4) {
  font-size: 24px;
  line-height: 1.67;
  animation: intro-description-4 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.intro__description--scroll-down {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translateX(-50%);
  transition: opacity 0.5s;
  /* animation: intro-scroll-down 1s linear forwards paused;
     animation-delay: calc(var(--progress-full) * -1s); */
}
.intro[data-scradar-end="0"] .intro__description--scroll-down {
  opacity: 0;
}
.intro[data-scradar-end="0"] .intro__video,
.intro[data-scradar-end="0"] .intro__description {
  position: absolute;
  top: auto;
  bottom: 0;
}
@media (max-width: 750px) {
  .intro__description br {
    display: none;
  }
  .intro__description--text {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 375px;
    width: 100%;
    padding: 0 24px;
    white-space: normal;
  }
}

/* 영문 */
[lang=en] .intro {
  /* tablet */
  /* mobile */
}
[lang=en] .intro__description--text {
  line-height: 1.46;
}
[lang=en] .intro__description--text:nth-child(3) {
  line-height: 1.67;
}
@media (max-width: 750px) {
  [lang=en] .intro__description--text {
    line-height: 1.33;
  }
  [lang=en] .intro__description--text:nth-child(2) br {
    display: block;
  }
  [lang=en] .intro__description--text:nth-child(3) {
    line-height: 1.7;
  }
}
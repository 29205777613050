@charset "UTF-8";
@keyframes gallery_title {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  65% {
    opacity: 0.1;
  }
  75% {
    opacity: 0.1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.pc-block {
  display: none !important;
}

.pc-block-block {
  display: none !important;
}

.pc-none {
  display: inline-block !important;
}

.pc-none-block {
  display: block !important;
}

/* pc */
@media (min-width: 750px) {
  .pc-block {
    display: inline-block !important;
  }

  .pc-block-block {
    display: block !important;
  }

  .pc-none,
.pc-none-block {
    display: none !important;
  }
}
.page__container {
  position: relative;
  margin: 0 100px;
  min-height: inherit;
  /* 1440 */
  /* 1024 */
  /* mobile */
}
@media (max-width: 1440px) {
  .page__container {
    margin: 0 72px;
  }
}
@media (max-width: 1024px) {
  .page__container {
    margin: 0 60px;
  }
}
@media (max-width: 750px) {
  .page__container {
    margin: 0 24px;
  }
}

/* challenge */
.challenge {
  transition: color 0.5s;
  /* challenge.html 안의 challenge */
  /* pc */
  /* mobile */
}
.challenge__title {
  font-size: 44px;
  line-height: 1.36;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.28;
}
@media screen and (min-width: 751px) {
  .challenge__title {
    font-size: 40px;
    line-height: 1.35;
  }
}
@media screen and (min-width: 1025px) {
  .challenge__title {
    font-size: 54px;
    line-height: 1.33;
  }
}
@media screen and (min-width: 1441px) {
  .challenge__title {
    font-size: 72px;
    line-height: 1.28;
  }
}
.challenge__description {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.67;
}
@media screen and (min-width: 751px) {
  .challenge__description {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .challenge__description {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .challenge__description {
    font-size: 24px;
    line-height: 1.67;
  }
}
.challenge.challenge--challenge .challenge__title {
  word-break: break-all;
}
body.dark .challenge {
  color: #fff;
}

@media (min-width: 751px) {
  .challenge {
    min-height: calc(var(--full-height, 100vh) * 1);
  }
  .challenge__wrap {
    display: flex;
    align-items: center;
    min-height: inherit;
  }
  .challenge__content {
    width: 100%;
    padding: 80px 0;
  }
  .challenge__title, .challenge__description {
    max-width: 50%;
  }
  .challenge__description {
    padding-left: 100px;
    box-sizing: border-box;
    margin-left: auto;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .challenge__description {
    padding-left: 55px;
  }
}
@media (min-width: 1441px) and (max-width: 1690px) {
  .challenge {
    /* asset.html 안의 challenge */
  }
  .challenge.challenge--asset .challenge__description br {
    display: none;
  }
}
@media (min-width: 751px) and (max-width: 1220px) {
  .challenge {
    /* asset.html 안의 challenge */
  }
  .challenge.challenge--asset .challenge__description br {
    display: none;
  }
}
@media (min-width: 751px) and (max-width: 1024px) {
  .challenge__description {
    padding-left: 30px;
  }
}
@media (max-width: 750px) {
  .challenge {
    padding: 112px 0 60px;
  }
  .challenge__title {
    line-height: 1.36;
  }
  .challenge__description {
    margin-top: 24px;
    line-height: 1.63;
  }
  .challenge__description br {
    display: none;
  }
  .challenge.challenge--feature {
    min-height: var(--full-height, 100vh);
    padding: 13.7931vh 0 0;
  }
  .challenge.challenge--feature .challenge__description br {
    display: none;
  }
}

/* 영문 */
[lang=en] .challenge {
  /* pc */
  /* mobile */
}
@media (min-width: 1441px) and (max-width: 1795px) {
  [lang=en] .challenge__description br {
    display: none;
  }
}
@media (min-width: 751px) and (max-width: 1300px) {
  [lang=en] .challenge__description br {
    display: none;
  }
}
@media (max-width: 750px) {
  [lang=en] .challenge__title {
    font-size: 40px;
    line-height: 1.35;
  }
  [lang=en] .challenge.challenge--feature {
    padding-bottom: 10.8374vh;
  }
}

/* prove */
.prove {
  position: relative;
  padding: 218px 0 226px;
  background: url("/assets/img/challenge/prove.jpg") no-repeat center center;
  background-size: cover;
  overflow: hidden;
  /* prove */
  /* pc */
  /* mobile */
}
.prove:before, .prove:after {
  content: "";
  position: absolute;
  max-width: 21.875%;
  width: calc(68% * max(var(--progress-visible) - 0.15, 0));
  height: 140px;
  background-color: #fff;
}
.prove:before {
  top: 0;
  right: 0;
}
.prove:after {
  bottom: 0;
  left: 0;
}
.prove__title--item {
  margin: 0 auto;
  font-size: 44px;
  line-height: 1.36;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.28;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 751px) {
  .prove__title--item {
    font-size: 40px;
    line-height: 1.35;
  }
}
@media screen and (min-width: 1025px) {
  .prove__title--item {
    font-size: 54px;
    line-height: 1.33;
  }
}
@media screen and (min-width: 1441px) {
  .prove__title--item {
    font-size: 72px;
    line-height: 1.28;
  }
}
.prove__title--item span {
  display: inline-block;
}
.prove__title--item:nth-child(1) span {
  transform: translateX(max(-535% * max(var(--progress-visible) - 0.15, 0), -172%));
}
.prove__title--item:nth-child(3) span {
  transform: translateX(min(417% * max(var(--progress-visible) - 0.15, 0), 134%));
}
.prove.prove--asset {
  background: url("/assets/img/asset/prove.jpg") no-repeat center center;
  background-size: cover;
}
.prove.prove--asset .prove__title--item:nth-child(1) span {
  transform: translateX(max(-354% * max(var(--progress-visible) - 0.15, 0), -114%));
}
.prove.prove--asset .prove__title--item:nth-child(3) span {
  transform: translateX(min(371% * max(var(--progress-visible) - 0.15, 0), 120%));
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .prove {
    padding: 180px 0;
  }
  .prove:before, .prove:after {
    height: 120px;
  }
}
@media (min-width: 751px) and (max-width: 1024px) {
  .prove {
    padding: 147px 0;
  }
  .prove:before, .prove:after {
    height: 72px;
  }
}
@media (max-width: 750px) {
  .prove {
    padding: 237px 0;
    background: url("/assets/img/challenge/prove-mo.jpg") no-repeat center center;
    background-size: cover;
    /* prove--asset */
  }
  .prove:before, .prove:after {
    max-width: 34.666%;
    width: calc(104% * max(var(--progress-visible) - 0.15, 0));
    height: 40px;
  }
  .prove__title--item {
    font-size: 32px;
    line-height: 1.31;
  }
  .prove__title--item:nth-child(1) span {
    transform: translateX(max(-408% * max(var(--progress-visible) - 0.15, 0), -136%));
  }
  .prove__title--item:nth-child(3) span {
    transform: translateX(min(223% * max(var(--progress-visible) - 0.15, 0), 75%));
  }
  .prove.prove--asset {
    background: url("/assets/img/asset/prove-mo.jpg") no-repeat center center;
    background-size: cover;
  }
  .prove.prove--asset .prove__title--item:nth-child(1) span {
    transform: translateX(max(-81% * max(var(--progress-visible) - 0.15, 0), -27%));
  }
  .prove.prove--asset .prove__title--item:nth-child(3) span {
    transform: translateX(min(105% * max(var(--progress-visible) - 0.15, 0), 35%));
  }
}

/* 영문 */
[lang=en] .prove {
  /* prove--asset */
  /* pc */
  /* mobile */
}
[lang=en] .prove__title--item:nth-child(1) span {
  transform: translateX(max(-575% * max(var(--progress-visible) - 0.15, 0), -185%));
}
[lang=en] .prove__title--item:nth-child(4) span {
  transform: translateX(min(401% * max(var(--progress-visible) - 0.15, 0), 129%));
}
[lang=en] .prove.prove--asset .prove__title--item:nth-child(1) span {
  transform: translateX(max(-294% * max(var(--progress-visible) - 0.15, 0), -95%));
}
[lang=en] .prove.prove--asset .prove__title--item:nth-child(3) span {
  transform: translateX(min(223% * max(var(--progress-visible) - 0.15, 0), 72%));
}
@media (min-width: 751px) {
  [lang=en] .prove {
    /* prove--challenge */
    /* prove--asset */
  }
  [lang=en] .prove.prove--challenge .prove__title--item:nth-child(3) {
    display: none;
  }
  [lang=en] .prove.prove--asset .prove__title--item:nth-child(4), [lang=en] .prove.prove--asset .prove__title--item:nth-child(5) {
    display: none;
  }
}
@media (max-width: 750px) {
  [lang=en] .prove {
    padding: 216px 0;
    /* prove--challenge */
    /* prove--asset */
  }
  [lang=en] .prove__title--item:nth-child(1) span {
    transform: translateX(max(-273% * max(var(--progress-visible) - 0.15, 0), -91%));
  }
  [lang=en] .prove__title--item:nth-child(3) span {
    transform: translateX(min(60% * max(var(--progress-visible) - 0.15, 0), 20%));
  }
  [lang=en] .prove__title--item:nth-child(4) span {
    transform: translateX(min(105% * max(var(--progress-visible) - 0.15, 0), 35%));
  }
  [lang=en] .prove.prove--challenge__title--item:nth-child(3) span {
    display: block !important;
  }
  [lang=en] .prove.prove--challenge__title--item:nth-child(3) span:nth-child(2) {
    margin-left: 80px;
  }
  [lang=en] .prove.prove--asset .prove__title--item:nth-child(3) {
    display: none;
  }
  [lang=en] .prove.prove--asset .prove__title--item:nth-child(1) span {
    transform: translateX(max(-69.5% * max(var(--progress-visible) - 0.15, 0), -23.5%));
  }
  [lang=en] .prove.prove--asset .prove__title--item:nth-child(4) span {
    transform: translateX(min(60.5% * max(var(--progress-visible) - 0.15, 0), 20.5%));
  }
  [lang=en] .prove.prove--asset .prove__title--item:nth-child(5) span {
    transform: translateX(min(223% * max(var(--progress-visible) - 0.15, 0), 75%));
  }
}

/* gallery */
.gallery {
  /* pc */
  /* mobile */
}
.gallery__title {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: calc(var(--full-height, 100vh) * 1);
  margin: 0;
  font-size: 44px;
  line-height: 1.36;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.28;
  z-index: -1;
}
@media screen and (min-width: 751px) {
  .gallery__title {
    font-size: 40px;
    line-height: 1.35;
  }
}
@media screen and (min-width: 1025px) {
  .gallery__title {
    font-size: 54px;
    line-height: 1.33;
  }
}
@media screen and (min-width: 1441px) {
  .gallery__title {
    font-size: 72px;
    line-height: 1.28;
  }
}
.gallery__title--item span {
  font-weight: 600;
}
.gallery__img {
  padding: calc(var(--full-height, 100vh) * 1) 0;
}
.gallery__img img {
  max-width: 100%;
}
.gallery__img--item {
  max-width: 46.4%;
  font-size: 0;
}
.gallery__img--item:not(:last-child) {
  margin-bottom: 186px;
}
.gallery__img--item:nth-child(2n-1) {
  margin-left: auto;
}
.gallery__img--title {
  margin: 12px 0 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}
@media (min-width: 751px) {
  .gallery__content {
    display: flex;
  }
  .gallery__title {
    -ms-flex: 0 0 470px;
    flex: 0 0 470px;
    max-width: 470px;
  }
  .gallery__img {
    -ms-flex: 0 0 calc(100% - 470px);
    flex: 0 0 calc(100% - 470px);
    max-width: calc(100% - 470px);
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .gallery__title {
    -ms-flex: 0 0 430px;
    flex: 0 0 430px;
    max-width: 430px;
  }
  .gallery__img {
    -ms-flex: 0 0 calc(100% - 430px);
    flex: 0 0 calc(100% - 430px);
    max-width: calc(100% - 430px);
  }
  .gallery__img--item:not(:last-child) {
    margin-bottom: 84px;
  }
}
@media (min-width: 751px) and (max-width: 1024px) {
  .gallery__title {
    -ms-flex: 0 0 290px;
    flex: 0 0 290px;
    max-width: 290px;
  }
  .gallery__img {
    -ms-flex: 0 0 calc(100% - 290px);
    flex: 0 0 calc(100% - 290px);
    max-width: calc(100% - 290px);
  }
  .gallery__img--item {
    max-width: 50%;
  }
  .gallery__img--item:not(:last-child) {
    margin-bottom: 82px;
  }
}
@media (max-width: 750px) {
  .gallery__title {
    position: absolute;
    animation: gallery_title 1s linear forwards paused;
    animation-delay: calc(var(--progress-visible) * -1s);
  }
  .gallery__img--item {
    max-width: 76.454%;
  }
  .gallery__img--item:not(:last-child) {
    margin-bottom: 180px;
  }
  .gallery__img--title {
    font-size: 14px;
    margin-top: 6px;
  }
  .gallery[data-scradar-start="1"][data-scradar-end="1"] .gallery__title {
    position: fixed;
  }
  .gallery[data-scradar-start="1"][data-scradar-end="0"] .gallery__title {
    top: inherit;
    bottom: 0;
  }
}

/* gallery 영문 */
[lang=en] .gallery {
  /* pc */
  /* mobile */
}
@media (min-width: 751px) {
  [lang=en] .gallery__title--item {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 200%;
  }
}
@media (max-width: 750px) {
  [lang=en] .gallery__title {
    font-size: 40px;
    line-height: 1.35;
  }
  [lang=en] .gallery__img--item:not(:last-child) {
    margin-bottom: 152px;
  }
}

/* possibility */
.possibility {
  transition: color 0.5s;
}
body.dark .possibility {
  color: #fff;
}

/* square */
.square {
  margin: 0;
  padding: 0;
  display: flex;
  /* mobile */
}
.square li {
  list-style: none;
}
.square__item {
  width: 300px;
  flex-shrink: 0;
}
.square__item:not(:last-child) {
  margin-right: 75px;
}
.square__item:not(:last-child) .square__icon:after {
  content: "";
  display: block;
  position: absolute;
  right: -44px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  border: 8px solid #fff;
  border-left: 0;
  border-bottom: 0;
}
.square__icon {
  display: block;
  border: 2px solid #fff;
  border-radius: 58px;
  background-color: #000;
  position: relative;
  width: 100%;
}
.square__icon:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.square__icon--content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square__icon img {
  max-width: 54%;
}
.square__title {
  margin-top: 24px;
  padding: 0 0 0 20px;
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
}
@media screen and (min-width: 751px) {
  .square__title {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .square__title {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .square__title {
    font-size: 24px;
    line-height: 1.67;
  }
}
@media (max-width: 750px) {
  .square__icon {
    border-radius: 40px;
  }
  .square__item:not(:last-child) {
    margin-right: 53px;
  }
  .square__item:not(:last-child) .square__icon:after {
    right: -34px;
    width: 16px;
    height: 16px;
    border-width: 7px;
  }
  .square__title {
    padding: 0 20px;
    margin-top: 12px;
  }
  .square__title br {
    display: none;
  }
}

/* graphic */
.graphic {
  width: calc(100vw - 100px);
  padding-left: 100px;
  /* mobile */
}
.graphic img {
  max-width: 100%;
  width: 100%;
}
.graphic__inner {
  position: relative;
  width: 506px;
  margin: 0 auto;
}
.graphic__img {
  position: relative;
  width: 100%;
  max-width: 100%;
}
.graphic__title--item {
  position: absolute;
  left: 0;
  text-align: center;
}
.graphic__title--item-title {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.42 !important;
}
@media screen and (min-width: 751px) {
  .graphic__title--item-title {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .graphic__title--item-title {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .graphic__title--item-title {
    font-size: 24px;
    line-height: 1.67;
  }
}
.graphic__title--item-subtitle {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  line-height: 1.63;
}
@media screen and (min-width: 751px) {
  .graphic__title--item-subtitle {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1025px) {
  .graphic__title--item-subtitle {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1441px) {
  .graphic__title--item-subtitle {
    font-size: 16px;
    line-height: 1.63;
  }
}
.graphic__title--item:nth-child(1) {
  top: 9%;
  width: 100%;
}
.graphic__title--item:nth-child(2), .graphic__title--item:nth-child(3) {
  width: 33.5%;
  bottom: 26.3%;
}
.graphic__title--item:nth-child(3) {
  left: inherit;
  right: 0;
}
@media (max-width: 750px) {
  .graphic {
    width: 100%;
    padding: 0;
  }
  .graphic__inner {
    width: 100%;
  }
  .graphic__title--item {
    left: 0;
  }
  .graphic__title--item-subtitle {
    margin-top: 0;
  }
  .graphic__title--item:nth-child(1) {
    top: 11%;
    width: 100%;
  }
  .graphic__title--item:nth-child(2), .graphic__title--item:nth-child(3) {
    width: 40%;
  }
  .graphic__title--item:nth-child(3) {
    right: 0;
  }
}

/* 영문 */
[lang=en] .graphic {
  /* pc */
}
@media (min-width: 751px) {
  [lang=en] .graphic__title--item:nth-child(1) {
    top: 11%;
  }
  [lang=en] .graphic__title--item:nth-child(2), [lang=en] .graphic__title--item:nth-child(3) {
    width: 36.5%;
    bottom: 26%;
  }
}

.chart__description {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4.1667vh;
  padding: 0 100px;
}
.chart__description-text {
  font-size: 12px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 400;
  line-height: 1.71;
  color: rgba(255, 255, 255, 0.6);
}
@media screen and (min-width: 751px) {
  .chart__description-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1025px) {
  .chart__description-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
@media screen and (min-width: 1441px) {
  .chart__description-text {
    font-size: 14px;
    line-height: 1.63;
  }
}
.chart__description.chart-mini {
  margin-top: 8%;
}

@media (max-width: 1440px) {
  .chart__description {
    padding: 0 72px;
  }
}
@media (max-width: 1024px) {
  .chart__description {
    padding: 0 60px;
  }
}
@media (max-width: 750px) {
  .chart__description {
    position: static;
    margin-top: 28px;
    margin-bottom: 60px;
    padding: 0 24px;
  }
  .chart__description-text {
    line-height: 1.83;
  }
  .chart__description-text:first-child {
    margin-bottom: 16px;
  }
}
@import '../abstracts/mixins';

@keyframes gallery_title {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  65% {
    opacity: 0.1;
  } 
  75% {
    opacity: 0.1;
  } 
  80% {
    opacity: 1;
  } 
  100% {
    opacity: 1;
  } 
} 

.pc-block {
  display: none !important;
}

.pc-block-block {
  display: none !important;
}

.pc-none {
  display: inline-block !important;
}

.pc-none-block {
  display: block !important;
}

/* pc */
@media (min-width: 750px) {
  .pc-block {
    display: inline-block !important;
  }

  .pc-block-block {
    display: block !important;
  }

  .pc-none,
  .pc-none-block {
    display: none !important;
  }
}

.page__container {
  position: relative;
  margin: 0 100px;
  min-height: inherit;

  /* 1440 */
  @media (max-width: 1440px) {
    margin: 0 72px;
  }

  /* 1024 */
  @media (max-width: 1024px) {
    margin: 0 60px;
  }

  /* mobile */
  @media (max-width: 750px) {
    margin: 0 24px;
  }
}


/* challenge */
.challenge {
  transition: color 0.5s;
  &__title {
    @include font-size(72px);
    font-weight: 600;
    line-height: 1.28;
  }

  &__description {
    @include font-size(24px);
    font-weight: 600;
    line-height: 1.67;
  }

  /* challenge.html 안의 challenge */
  &.challenge--challenge {
    .challenge__title {
      word-break: break-all;
    }
  }

  @at-root body.dark .challenge {
    color: #fff;
  }

  /* pc */
  @media (min-width: 751px) {
    min-height: calc(var(--full-height, 100vh) * 1);
  
    &__wrap {
      display: flex;
      align-items: center;
      min-height: inherit;
    }

    &__content {
      width: 100%;
      padding: 80px 0;
    }
    
    &__title, 
    &__description {
      max-width: 50%;
    }
  
    &__description {
      padding-left: 100px;
      box-sizing: border-box;
      margin-left: auto;
    }
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    &__description {
      padding-left: 55px;
    }
  }

  @media (min-width: 1441px) and (max-width: 1690px) {
    /* asset.html 안의 challenge */
    &.challenge--asset {
      .challenge__description {
        br {
          display: none;
        }
      }
    }
  }

  @media (min-width: 751px) and (max-width: 1220px) {
    /* asset.html 안의 challenge */
    &.challenge--asset {
      .challenge__description {
        br {
          display: none;
        }
      }
    }
  }

  @media (min-width: 751px) and (max-width: 1024px) {
    &__description {
      padding-left: 30px;
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    padding: 112px 0 60px;

    &__title {
      line-height: 1.36;
    }

    &__description {
      margin-top: 24px;
      line-height: 1.63;
      br {
        display: none;
      }
    }

    &.challenge--feature {
      min-height: var(--full-height, 100vh);
      padding: 13.7931vh 0 0;
      .challenge__description br {
        display: none;
      }
    }
  }
}

/* 영문 */
[lang="en"] .challenge {
  /* pc */
  @media (min-width: 1441px) and (max-width: 1795px) {
    &__description {
      br {
        display: none;
      }
    }
  }

  @media (min-width: 751px) and (max-width: 1300px) {
    &__description {
      br {
        display: none;
      }
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    &__title {
      font-size: 40px;
      line-height: 1.35;
    }

    &.challenge--feature {
      padding-bottom: 10.8374vh;
    }
  }
}

/* prove */
.prove {
  position: relative;
  padding: 218px 0 226px;
  background: url('/assets/img/challenge/prove.jpg') no-repeat center center;
  background-size: cover;
  overflow: hidden;

  &:before,
  &:after{
    content: '';
    position: absolute;
    max-width: 21.875%;
    width: calc(68% * (max(var(--progress-visible) - 0.15, 0)));
    height: 140px;
    background-color: #fff;
  }

  &:before {
    top: 0;
    right: 0;
  }

  &:after {
    bottom: 0;
    left: 0;
  }

  &__title {
    &--item {
      margin: 0 auto;
      @include font-size(72px);
      font-weight: 600;
      line-height: 1.28;
      color: #fff;
      text-align: center;
      span {
        display: inline-block;
      }
      &:nth-child(1) {
        span {
          transform: translateX(max(-535% * max(var(--progress-visible) - 0.15,0),-172%));
        }
      }
      &:nth-child(3) {
        span {
          transform: translateX(min(417% * max(var(--progress-visible) - 0.15,0),134%));
        }
      }
    }
  }

  /* prove */
  &.prove--asset {
    background: url('/assets/img/asset/prove.jpg') no-repeat center center;
    background-size: cover;
    .prove__title {
      &--item {
        &:nth-child(1) {
          span {
            transform: translateX(max(-354% * max(var(--progress-visible) - 0.15,0),-114%));
          }
        }
        &:nth-child(3) {
          span {
            transform: translateX(min(371% * max(var(--progress-visible) - 0.15,0),120%));
          }
        }
      }
    }
  }

  /* pc */
  @media (min-width: 1025px) and (max-width: 1440px) {
    padding: 180px 0;

    &:before,
    &:after{
      height: 120px;
    }
  }

  @media (min-width: 751px) and (max-width: 1024px) {
    padding: 147px 0;

    &:before,
    &:after{
      height: 72px;
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    padding: 237px 0;
    background: url('/assets/img/challenge/prove-mo.jpg') no-repeat center center;
    background-size: cover;

    &:before,
    &:after{
      max-width: 34.666%;
      width: calc(104% * (max(var(--progress-visible) - 0.15, 0)));
      height: 40px;
    }

    &__title {
      &--item {
        font-size: 32px;
        line-height: 1.31;
        &:nth-child(1) {
          span {
            transform: translateX(max(-408% * max(var(--progress-visible) - 0.15,0),-136%));
          }
        }
        &:nth-child(3) {
          span {
            transform: translateX(min(223% * max(var(--progress-visible) - 0.15,0),75%));
          }
        }
      }
    }

    /* prove--asset */
    &.prove--asset {
      background: url('/assets/img/asset/prove-mo.jpg') no-repeat center center;
      background-size: cover;

      .prove__title {
        &--item {
          &:nth-child(1) {
            span {
              transform: translateX(max(-81% * max(var(--progress-visible) - 0.15,0),-27%));
            }
          }
          &:nth-child(3) {
            span {
              transform: translateX(min(105% * max(var(--progress-visible) - 0.15,0),35%));
            }
          }
        }
      }
    }
  }
}

/* 영문 */
[lang="en"] .prove {
  &__title {
    &--item {
      &:nth-child(1) {
        span {
          transform: translateX(max(-575% * max(var(--progress-visible) - 0.15,0),-185%));
        }
      }
      &:nth-child(4) {
        span {
          transform: translateX(min(401% * max(var(--progress-visible) - 0.15,0),129%));
        }
      }
    }
  }

  /* prove--asset */
  &.prove--asset {
    .prove__title {
      &--item {
        &:nth-child(1) {
          span {
            transform: translateX(max(-294% * max(var(--progress-visible) - 0.15,0),-95%));
          }
        }
        &:nth-child(3) {
          span {
            transform: translateX(min(223% * max(var(--progress-visible) - 0.15,0),72%));
          }
        }
      }
    }
  }

  /* pc */
  @media (min-width: 751px) {
    /* prove--challenge */
    &.prove--challenge {
      .prove__title {
        &--item {
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }

    /* prove--asset */
    &.prove--asset {
      .prove__title {
        &--item {
          &:nth-child(4),
          &:nth-child(5) {
            display: none;
          }
        }
      }
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    padding: 216px 0;

    &__title {
      &--item {
        &:nth-child(1) {
          span {
            transform: translateX(max(-273% * max(var(--progress-visible) - 0.15,0),-91%));
          }
        }
        &:nth-child(3) {
          span {
            transform: translateX(min(60% * max(var(--progress-visible) - 0.15,0),20%));
          }
        }
        &:nth-child(4) {
          span {
            transform: translateX(min(105% * max(var(--progress-visible) - 0.15,0),35%));
          }
        }
      }
    }
  
    /* prove--challenge */
    &.prove--challenge {
      &__title {
        &--item {
          &:nth-child(3) {
            span {
              display: block !important;
              &:nth-child(2) {
                margin-left: 80px;
              }
            }
          }
        }
      }
    }

    /* prove--asset */
    &.prove--asset {
      .prove__title {
        &--item {
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(1) {
            span {
              transform:translateX(max(-69.5% * max(var(--progress-visible) - .15,0),-23.5%));
            }
          }
          &:nth-child(4) {
            span {
              transform: translateX(min(60.5% * max(var(--progress-visible) - .15,0),20.5%));
            }
          }
          &:nth-child(5) {
            span {
              transform: translateX(min(223% * max(var(--progress-visible) - .15,0),75%));
            }
          }
        }
      }
    }
  }
}

/* gallery */
.gallery {
  &__title {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    height: calc(var(--full-height, 100vh) * 1);
    margin: 0;
    @include font-size(72px);
    font-weight: 600;
    line-height: 1.28;
    z-index: -1;
    &--item {
      span {
        font-weight: 600;
      }
    }
  }

  &__img {
    padding: calc(var(--full-height, 100vh) * 1) 0;
    img {
      max-width: 100%;
    }
    &--item {
      max-width: 46.4%;
      font-size: 0;
      &:not(:last-child) {
        margin-bottom: 186px;
      }
      &:nth-child(2n-1) {
        margin-left: auto;
      }
    }
    &--title {
      margin: 12px 0 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  /* pc */
  @media (min-width: 751px) {
    &__content {
      display: flex;
    }
  
    &__title {
      -ms-flex: 0 0 470px;
      flex: 0 0 470px;
      max-width: 470px;
    }

    &__img {
      -ms-flex: 0 0 calc(100% - 470px); //72.674%;
      flex: 0 0 calc(100% - 470px);
      max-width: calc(100% - 470px);
    }
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    &__title {
      -ms-flex: 0 0 430px;
      flex: 0 0 430px;
      max-width: 430px;
    }
    &__img {
      -ms-flex: 0 0 calc(100% - 430px);
      flex: 0 0 calc(100% - 430px);
      max-width: calc(100% - 430px);
      &--item {
        &:not(:last-child) {
          margin-bottom: 84px;
        }
      }
    }
  }
  @media (min-width: 751px) and (max-width: 1024px) {
    &__title {
      -ms-flex: 0 0 290px;
      flex: 0 0 290px;
      max-width: 290px;
    }
    &__img {
      -ms-flex: 0 0 calc(100% - 290px);
      flex: 0 0 calc(100% - 290px);
      max-width: calc(100% - 290px);
      &--item {
        max-width: 50%;
        &:not(:last-child) {
          margin-bottom: 82px;
        }
      }
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    &__title {
      position: absolute;
      animation: gallery_title 1s linear forwards paused;
      animation-delay: calc(var(--progress-visible) * -1s);
    }

    &__img {
      &--item {
        max-width: 76.454%;
        &:not(:last-child) {
          margin-bottom: 180px;
        }
      }
      &--title {
        font-size: 14px;
        margin-top: 6px;
      }
    }

    &[data-scradar-start="1"][data-scradar-end="1"] {
      .gallery__title {
        position: fixed;
      }
    }

    &[data-scradar-start="1"][data-scradar-end="0"] {
      .gallery__title {
        top: inherit;
        bottom: 0;
      }
    }
  }
}

/* gallery 영문 */
[lang="en"] .gallery {
  /* pc */
  @media (min-width: 751px) {
    &__title--item {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 200%;
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    &__title {
      font-size: 40px;
      line-height: 1.35;
    }

    &__img--item:not(:last-child) {
      margin-bottom: 152px;
    }
  }
}


/* possibility */
.possibility {
  transition: color 0.5s;
  @at-root body.dark .possibility {
    color: #fff;
  }
}

/* square */
.square {
  margin: 0;
  padding: 0;
  display: flex;

  li {
    list-style: none;
  }

  &__item {
    width: 300px;
    flex-shrink: 0;
    &:not(:last-child) {
      margin-right: 75px;
      .square__icon {
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: -44px;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          width: 20px;
          height: 20px;
          border: 8px solid #fff;
          border-left: 0;
          border-bottom: 0;
        }
      }
    }
  }

  &__icon {
    display: block;
    border: 2px solid #fff;
    border-radius: 58px;
    background-color: #000;
    position: relative;
    width: 100%;
    &:before{
      content: "";
      display: block;
      padding-top: 100%;
    }
    &--content{
      position:  absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 54%;
    }
  }

  &__title {
    margin-top: 24px;
    padding: 0 0 0 20px;
    @include font-size(24px);
    // line-height: 1.67;
  }

  /* mobile */
  @media (max-width: 750px) {
    // display: block;
    // padding: 0 24px;

    &__icon {
      border-radius: 40px;
    }

    &__item {
      // width: 100%;
      // margin-right: 0;
      &:not(:last-child) {
        margin-right: 53px;
        .square__icon {
          &:after {
            right: -34px;
            width: 16px;
            height: 16px;
            border-width: 7px;
          }
        }
      }
    }
    &__title {
      padding: 0 20px;
      margin-top: 12px;
      br {
        display: none;
      }
    }
  }
}

/* graphic */
.graphic {
  width: calc(100vw - 100px);
  padding-left: 100px;
  img {
    max-width: 100%;
    width: 100%;
  }
  &__inner {
    position: relative;
    width: 506px;
    margin:0 auto;
  }
  &__img {
    position: relative;
    width: 100%;
    max-width: 100%;
  }
  &__title {
    &--item {
      position: absolute;
      left: 0;
      text-align: center;
      &-title {
        @include font-size(24px);
        font-weight: 600;
        line-height: 1.42 !important;
      }
      &-subtitle {
        @include font-size(16px);
        line-height: 1.63;
      }
      &:nth-child(1) {
        top: 9%;
        width: 100%;
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: 33.5%;
        bottom: 26.3%;
      }
      &:nth-child(3) {
        left: inherit;
        right: 0;
      }
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    width: 100%;
    // margin: 82px 0 0;
    padding: 0;
    &__inner {
      width: 100%;
    }
    &__title {
      &--item {
        left: 0;
        &-subtitle {
          margin-top: 0;
        }
        &:nth-child(1) {
          top: 11%;
          width: 100%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 40%;
        }
        &:nth-child(3) {
          right: 0;
        }
      }
    }
  }
}


/* 영문 */
[lang="en"] .graphic {
  /* pc */
  @media (min-width: 751px) {
    &__title {
      &--item {
        &:nth-child(1) {
          top: 11%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 36.5%;
          bottom: 26%;
        }
      }
    }
  }
}

.chart__description {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4.1667vh;
  padding: 0 100px;
  &-text {
    @include font-size(14px);
    font-weight: 400;
    line-height: 1.71;
    color: rgba(255,255,255,.6);
  }

  &.chart-mini {
    margin-top: 8%;
  }
}
@media (max-width: 1440px) {
  .chart__description {
    padding: 0 72px;
  }
}
@media (max-width: 1024px) {
  .chart__description {
    padding: 0 60px;
  }
}
@media (max-width: 750px) {
  .chart__description {
    position: static;
    margin-top: 28px;
    margin-bottom: 60px;
    padding:0 24px;
    &-text {
      line-height: 1.83;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
}
@charset "UTF-8";
* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Archivo", "NotoSansCJKkr";
}

body {
  font-family: "Archivo", "NotoSansCJKkr";
  margin: 0;
  word-break: keep-all;
  overflow-x: hidden;
  transition: background-color 0.5s;
}

body.dark {
  background-color: #000;
}

[lang=en] h1, [lang=en] h2, [lang=en] h3, [lang=en] h4, [lang=en] h5, [lang=en] h6, [lang=en] body {
  font-family: "Archivo";
}

body.scroll-none {
  overflow: hidden;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input,
select,
textarea,
button,
a {
  margin: 0;
  border-width: 0;
  background: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input:focus {
  outline: none;
}

input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.font-archivo {
  font-family: "Archivo";
}

.font-notosans {
  font-family: "NotoSansCJKkr";
}

/* NotoSansCJKkr */
@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 100;
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Thin.eot");
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Thin.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Thin.woff2") format("woff2"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Thin.woff") format("woff");
}
@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 300;
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Light.eot");
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Light.woff2") format("woff2"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Light.woff") format("woff");
}
@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 400;
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Regular.eot");
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Regular.woff2") format("woff2"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Regular.woff") format("woff"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 500;
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Medium.eot");
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Medium.woff2") format("woff2"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Medium.woff") format("woff");
}
@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 700;
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Bold.eot");
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Bold.woff2") format("woff2"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Bold.woff") format("woff");
}
@font-face {
  font-family: "NotoSansCJKkr";
  font-weight: 900;
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Black.eot");
  src: url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Black.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Black.woff2") format("woff2"), url("/assets/fonts/NotoSansCJKkr/NotoSansCJKkr-Black.woff") format("woff");
}
/* Archivo */
@font-face {
  font-family: "Archivo";
  font-weight: 100;
  src: url("/assets/fonts/Archivo/Archivo-Thin.eot");
  src: url("/assets/fonts/Archivo/Archivo-Thin.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-Thin.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-Thin.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-Thin.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: "Archivo";
  font-weight: 200;
  src: url("/assets/fonts/Archivo/Archivo-ExtraLight.eot");
  src: url("/assets/fonts/Archivo/Archivo-ExtraLight.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-ExtraLight.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-ExtraLight.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-ExtraLight.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: "Archivo";
  font-weight: 300;
  src: url("/assets/fonts/Archivo/Archivo-Light.eot");
  src: url("/assets/fonts/Archivo/Archivo-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-Light.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-Light.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-Light.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: "Archivo";
  font-weight: 400;
  src: url("/assets/fonts/Archivo/Archivo-Regular.eot");
  src: url("/assets/fonts/Archivo/Archivo-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-Regular.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-Regular.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-Regular.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: "Archivo";
  font-weight: 500;
  src: url("/assets/fonts/Archivo/Archivo-Medium.eot");
  src: url("/assets/fonts/Archivo/Archivo-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-Medium.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-Medium.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-Medium.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: "Archivo";
  font-weight: 600;
  src: url("/assets/fonts/Archivo/Archivo-SemiBold.eot");
  src: url("/assets/fonts/Archivo/Archivo-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-SemiBold.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-SemiBold.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-SemiBold.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: "Archivo";
  font-weight: 700;
  src: url("/assets/fonts/Archivo/Archivo-Bold.eot");
  src: url("/assets/fonts/Archivo/Archivo-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-Bold.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-Bold.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-Bold.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: "Archivo";
  font-weight: 800;
  src: url("/assets/fonts/Archivo/Archivo-ExtraBold.eot");
  src: url("/assets/fonts/Archivo/Archivo-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-ExtraBold.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-ExtraBold.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-ExtraBold.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
@font-face {
  font-family: "Archivo";
  font-weight: 900;
  src: url("/assets/fonts/Archivo/Archivo-Black.eot");
  src: url("/assets/fonts/Archivo/Archivo-Black.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Archivo/Archivo-Black.woff2") format("woff2"), url("/assets/fonts/Archivo/Archivo-Black.woff") format("woff"), url("/assets/fonts/Archivo/Archivo-Black.ttf") format("truetype");
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}
/* header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 100;
  transform: translateY(-100%);
  transition: transform 0.3s, color 0.5s;
  /* header--dark */
  /* mobile */
  /* 1440 */
  /* 1024 */
}
.use--keyboard .header, .header.show {
  transform: translateY(0);
}

.header a {
  color: #fff;
  text-decoration: none;
  transition: color 0.5s;
}
.header ul {
  margin: 0;
  padding: 0;
}
.header li {
  list-style: none;
}
.header svg path {
  transition: stroke 0.5s;
}
.header__content {
  display: flex;
  justify-content: space-between;
}
.header__container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 100px;
}
.header__logo {
  width: 100px;
}
.header__logo--link {
  display: inline-block;
  padding: 17px 0;
}
.header__logo--link img {
  width: 100%;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  transition: filter 0.5s;
}
.header__nav {
  position: relative;
  display: flex;
  align-items: center;
}
.header__nav--link {
  display: inline-block;
  padding: 23px 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
.header__lang {
  position: relative;
  cursor: pointer;
}
.header__lang--title {
  display: flex;
  align-items: center;
  padding: 20px 0 18px 25px;
}
.header__lang--text {
  display: inline-block;
  margin: 0 5px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
.header__lang--subnav {
  position: absolute;
  right: 0;
  top: 53px;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  background-color: #000;
  border: solid 1px #5a5a5a;
  border-radius: 6px;
  transition: all 0.3s;
  /* show */
}
.header__lang--subnav-item .header__lang--subnav-link {
  display: block;
  padding: 2px 14px 4px 13px;
  font-size: 18px;
  line-height: 1.33;
  font-weight: 600;
}
.header__lang--subnav-item .header__lang--subnav-link span {
  opacity: 0.2;
}
.header__lang--subnav-item .header__lang--subnav-link.active span {
  opacity: 1;
}
.header__lang--subnav-item:first-child .header__lang--subnav-link {
  padding-top: 6px;
  padding-bottom: 2px;
}
.use--keyboard .header__lang:focus .header__lang--subnav, .header__lang--subnav.show {
  opacity: 1;
  visibility: visible;
}

.header__hamburger {
  display: none;
  box-sizing: border-box;
  padding: 21px 3px;
  z-index: 120;
  /* show */
}
.header__hamburger--inner {
  position: relative;
  width: 24px;
  height: 14px;
}
.header__hamburger--item {
  position: absolute;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s;
}
.header__hamburger--item.top {
  top: 0;
  width: 12px;
}
.header__hamburger--item.middle {
  top: 50%;
  transform: translateY(-50%);
}
.header__hamburger--item.bottom {
  bottom: 0;
}
.header__hamburger.show .header__hamburger--item {
  background-color: #000 !important;
}
.header__hamburger.show .top {
  width: 100%;
  top: 50%;
  transform: rotate(135deg);
}
.header__hamburger.show .middle {
  opacity: 0;
}
.header__hamburger.show .bottom {
  top: 50%;
  transform: rotate(-135deg);
}
.header__menu {
  display: flex;
  position: fixed;
  top: calc(var(--full-height, 100vh) * -1);
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(var(--full-height, 100vh) * 1);
  padding: 70px 24px 24px !important;
  background-color: #fff;
  z-index: 110;
  box-sizing: border-box;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: top 0.4s, opacity 0.4s, visibility 0.4s;
}
.header__menu > * {
  opacity: 0;
}
.header__menu a {
  display: inline-block;
  color: #000;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.31;
  padding: 14px 0;
}
.header__menu .header__lang--mo-item {
  display: inline;
}
.header__menu .header__lang--mo-item:not(:last-child) {
  margin-right: 20px;
}
.header__menu .header__lang--mo-link {
  color: #c0c0c0 !important;
}
.header__menu .header__lang--mo-link.active {
  color: #000 !important;
}
.header__menu--logo {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.36;
  color: #000;
}
.header.header--dark {
  color: #000;
}
.header.header--dark a {
  color: #000;
}
.header.header--dark img {
  -webkit-filter: brightness(0) invert(0);
  filter: brightness(0) invert(0);
}
.header.header--dark .header__lang--subnav {
  background-color: #fff;
  border-color: #c5c5c5;
}
.header.header--dark .header__lang svg path {
  stroke: #000;
}
@media (max-width: 750px) {
  .header {
    transform: translateY(-100%);
    /* header--dark */
  }
  .header__container {
    padding: 0 21px 0 24px;
  }
  .header__logo {
    width: 50px;
  }
  .header__logo--link {
    padding: 13px 0;
  }
  .header__nav {
    display: none;
  }
  .header__hamburger {
    display: block;
  }
  .header__menu {
    /* show */
  }
  .header__menu.show {
    top: 0;
    opacity: 1;
    visibility: visible;
  }
  .header__menu.show > * {
    opacity: 1;
    transition: opacity 0.45s 0.4s;
  }
  .header.header--dark .header__hamburger--item {
    background-color: #000;
  }
  .header.header--dark .header__hamburger.show .header__hamburger--item {
    background-color: #fff;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .header__container {
    padding: 0 72px;
  }
}
@media (min-width: 751px) and (max-width: 1024px) {
  .header__container {
    padding: 0 60px;
  }
  .header__menu {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}

/* top__btn */
.top__btn {
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 100px;
  bottom: 40px;
  z-index: 50;
  background-color: #111;
  width: 50px;
  height: 50px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  /* 1440 */
  /* 1024 */
  /* mobile */
}
.top__btn svg {
  width: 35px;
  height: 30px;
}
.top__btn.show {
  opacity: 1;
  visibility: visible;
}
.top__btn.show.video-top-btn {
  opacity: 0;
  visibility: hidden;
}
.top__btn.stop {
  position: absolute;
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .top__btn {
    right: 72px;
  }
}
@media (min-width: 751px) and (max-width: 1024px) {
  .top__btn {
    right: 60px;
  }
}
@media (max-width: 750px) {
  .top__btn {
    right: 24px;
    bottom: 24px;
  }
}

html[data-scradar-scroll="1"] .top__btn {
  opacity: 0;
  visibility: hidden;
}
html[data-scradar-scroll="1"] .top__btn.stop {
  opacity: 1;
  visibility: visible;
}

/* footer */
.footer {
  background: #000;
  position: relative;
  color: #fff;
  padding: 0 100px;
}
.footer__container {
  display: flex;
  justify-content: space-between;
  padding: 58px 0;
}
.footer__logo img {
  width: 193px;
  display: block;
}
.footer__logo-mobile {
  display: none;
}
.footer__info-link a {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
.footer__info-text {
  font-size: 14px;
}
.footer__info-address {
  font-weight: 400;
  line-height: 1;
}
.footer__info-copyright {
  margin-top: 10px;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
}

/* 1440 */
@media (min-width: 1025px) and (max-width: 1440px) {
  .footer {
    padding: 0 72px;
  }
}
/* 1024 */
@media (min-width: 751px) and (max-width: 1024px) {
  .footer {
    padding: 0 60px;
  }
}
/* mobile */
@media (max-width: 750px) {
  .footer {
    padding: 0 24px;
  }
}
@media (max-width: 900px) {
  .footer__container {
    display: block;
    padding: 36px 0;
  }
  .footer__logo img {
    width: 154px;
  }
  .footer__logo-mobile {
    display: block;
  }
  .footer__logo-pc {
    display: none;
  }
  .footer__info {
    padding-top: 44px;
  }
  .footer__info-address {
    line-height: 1.43;
  }
  .footer__info-copyright {
    line-height: 1.43;
  }
  .footer__info-copyright span {
    display: inline-block;
  }
}
.blind {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px;
}

.use--keyboard *:focus {
  outline: 2px #0656c3 solid !important;
}

.slide {
  position: relative;
  height: max(var(--full-height, 100vh) + var(--scroll-width) * 1px, var(--full-height, 100vh));
}
.slide[data-scradar-start="1"][data-scradar-end="1"] .slide__wrap {
  position: fixed;
}
.slide__wrap {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--full-height, 100vh);
  padding: 0 100px;
  overflow: hidden;
}
.slide__inner {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: var(--full-height, 100vh);
  transform: translate3d(calc((var(--scroll-width) * 1px + var(--space) * 1px) * var(--progress-full) * -1), 0, 0);
}
.slide__container {
  display: flex;
  align-items: center;
}
.slide__head {
  flex-shrink: 0;
  padding: 0 160px 0 0;
}
.slide__head .title {
  font-size: 44px;
  line-height: 1.36;
  font-weight: bold;
}
@media screen and (min-width: 751px) {
  .slide__head .title {
    font-size: 40px;
    line-height: 1.35;
  }
}
@media screen and (min-width: 1025px) {
  .slide__head .title {
    font-size: 54px;
    line-height: 1.33;
  }
}
@media screen and (min-width: 1441px) {
  .slide__head .title {
    font-size: 72px;
    line-height: 1.28;
  }
}
body.dark .slide__head .title {
  color: #fff;
  transition: color 0.5s;
}

.slide__body {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.slide__item {
  flex-shrink: 0;
}
.slide__bottom {
  flex-shrink: 0;
  padding: 0 60px 0 160px;
}
.slide__bottom .slide__description {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
}
@media screen and (min-width: 751px) {
  .slide__bottom .slide__description {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .slide__bottom .slide__description {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .slide__bottom .slide__description {
    font-size: 24px;
    line-height: 1.67;
  }
}
.slide-sub__wrap {
  flex-shrink: 0;
}
.slide-sub__inner {
  display: flex;
  align-items: center;
}
@media (max-width: 1440px) {
  .slide__wrap {
    padding: 0 72px;
  }
  .slide__head {
    padding: 0 104px 0 0;
  }
  .slide__bottom {
    padding: 0 30px 0 104px;
  }
}
@media (max-width: 1024px) {
  .slide__wrap {
    padding: 0 60px;
  }
  .slide__head {
    padding: 0 80px 0 0;
  }
  .slide__bottom {
    padding: 0 20px 0 80px;
  }
}
@media (max-width: 750px) {
  .slide {
    height: calc(var(--full-height, 100vh) * 5);
  }
  .slide__wrap {
    padding: 0;
    overflow: visible;
  }
  .slide__inner {
    display: block;
  }
  .slide__head {
    padding: 13.7931vh 24px 36px;
  }
  .slide__body {
    padding: 0 24px;
    overflow: hidden;
  }
  .slide__bottom {
    padding: 0 24px;
  }
  .slide__bottom .slide__description {
    font-size: 20px;
    line-height: 1.7;
  }
  .slide-sub {
    height: auto;
  }
  .slide-sub[data-scradar-start="1"][data-scradar-end="1"] .slide__wrap {
    position: relative;
  }
  .slide-sub .slide__wrap {
    position: relative;
    height: auto;
  }
  .slide-sub .slide__inner {
    height: auto;
    transform: none;
  }
  .slide-sub__wrap {
    position: relative;
    height: max(var(--full-height, 100vh) + var(--scroll-width) * 1px, var(--full-height, 100vh));
  }
  .slide-sub__inner {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--full-height, 100vh);
    overflow: hidden;
  }
  .slide-sub__inner .slide__body > * {
    transform: translate3d(calc((var(--scroll-width) * 1px + 48px) * var(--progress-full) * -1), 0, 0);
  }
  .slide--asset {
    height: auto;
  }
  .slide--asset .slide__wrap {
    height: auto;
    overflow: visible;
  }
  .slide--asset .slide__inner {
    height: auto;
    transform: none;
  }
  .slide--asset .slide__item {
    margin: 0 auto;
  }
  .slide--asset[data-scradar-start="1"][data-scradar-end="1"] .slide__wrap {
    position: relative;
  }
  .slide--possibility .slide__head {
    padding: 13.7931vh 24px 72px;
  }
  .slide--possibility .slide__bottom {
    padding-top: 40px;
  }
}

@media (max-width: 375px) and (min-aspect-ratio: 1/2) {
  .slide:not(.slide--asset) .slide__head {
    padding: 10.8374vh 24px 28px;
  }
  .slide:not(.slide--asset) .slide__head .title {
    font-size: 28px;
    line-height: 1.36;
  }
  .slide:not(.slide--asset) .slide__head .title br {
    display: none;
  }
  .slide:not(.slide--asset) .slide__head .title br ~ br {
    display: inline;
  }
}
.swiper__navi {
  display: none;
}
@media (max-width: 750px) {
  .swiper {
    margin: 0 -24px;
    padding: 0 24px;
  }
  .creator .swiper {
    padding: 0 39px;
  }

  .swiper__navi {
    display: block;
    margin-top: 32px;
    text-align: right;
  }
  .swiper__navi .navi__button {
    width: 28px;
    height: 28px;
    padding: 0;
    border: 0 none;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .swiper__navi .navi__button--prev {
    background-image: url("/assets/img/service/icon-nav-prev-black-on.png");
  }
  .swiper__navi .navi__button--prev.navi__button--disable {
    background-image: url("/assets/img/service/icon-nav-prev-black-off.png");
  }
  .swiper__navi .navi__button--next {
    margin-left: 16px;
    background-image: url("/assets/img/service/icon-nav-next-black-on.png");
  }
  .swiper__navi .navi__button--next.navi__button--disable {
    background-image: url("/assets/img/service/icon-nav-next-black-off.png");
  }
  .swiper__navi--white .navi__button--prev {
    background-image: url("/assets/img/service/icon-nav-prev-white-on.png");
  }
  .swiper__navi--white .navi__button--prev.navi__button--disable {
    background-image: url("/assets/img/service/icon-nav-prev-white-off.png");
  }
  .swiper__navi--white .navi__button--next {
    margin-left: 16px;
    background-image: url("/assets/img/service/icon-nav-next-white-on.png");
  }
  .swiper__navi--white .navi__button--next.navi__button--disable {
    background-image: url("/assets/img/service/icon-nav-next-white-off.png");
  }
}

/* 영문 */
[lang=en] {
  /* mobile */
}
@media (max-width: 750px) {
  [lang=en] .slide__title {
    font-size: 40px;
    line-height: 1.35;
  }
}
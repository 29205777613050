@charset "UTF-8";
@keyframes card-grow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes card-grow-mb {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.text-gradient {
  background-image: linear-gradient(90deg, #43cf2e, #0049ff, #e657af);
  background-size: cover;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.mobile-br {
  display: none;
}

.pc-br {
  display: block;
}

@media (max-width: 750px) {
  .mobile-br {
    display: block;
  }

  .pc-br {
    display: none;
  }
}
body:not(.dark) .service * {
  color: #000 !important;
}

.service {
  position: relative;
}
.service__container {
  display: flex;
  margin-top: calc(var(--full-height, 100vh) * -1);
  padding: 0 100px;
}
.service__container[data-scradar-start="0"][data-scradar-end="1"], .service__container[data-scradar-start="1"][data-scradar-end="0"] {
  opacity: 0;
}
.service__container[data-scradar-start="1"][data-scradar-end="1"] {
  opacity: 1;
}
.service__container[data-scradar-start="1"][data-scradar-end="1"] .service__main--title {
  position: fixed;
}
.service__container[data-scradar-start="1"][data-scradar-end="1"] .service__main--area {
  margin-left: 400px;
}
.service__main--title {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 400px;
  height: calc(var(--full-height, 100vh) * 1);
  color: #fff;
}
.service__main--title .card-text {
  opacity: min(var(--window-height) * var(--progress-full) / (var(--window-height) * 0.2), 1);
}
.service__main--title p {
  display: none;
}
.service__main--title-card {
  position: relative;
  width: 400px;
  height: 540px;
  border-radius: 20px;
  border: 2px solid transparent;
  background-image: linear-gradient(#000, #000), linear-gradient(300deg, #e657af, #0049ff, #43cf2e);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.service__main--title-card .card-text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 40px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(90deg, #43cf2e, #0049ff, #e657af);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (min-width: 751px) {
  .service__main--title-card .card-text {
    font-size: 24px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1025px) {
  .service__main--title-card .card-text {
    font-size: 36px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1441px) {
  .service__main--title-card .card-text {
    font-size: 40px;
    line-height: 1.25;
  }
}
.service__main--area {
  flex: 0 0 calc(100% - 400px);
  padding: calc(var(--full-height, 100vh) * 1) 0 calc(var(--full-height, 100vh) * 1) 13.5417vw;
}
.service__content--title {
  font-size: 44px;
  line-height: 1.36;
  font-weight: bold;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 751px) {
  .service__content--title {
    font-size: 40px;
    line-height: 1.35;
  }
}
@media screen and (min-width: 1025px) {
  .service__content--title {
    font-size: 54px;
    line-height: 1.33;
  }
}
@media screen and (min-width: 1441px) {
  .service__content--title {
    font-size: 72px;
    line-height: 1.28;
  }
}
.service__content--description {
  max-width: 750px;
  margin-top: 34px;
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 751px) {
  .service__content--description {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .service__content--description {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .service__content--description {
    font-size: 24px;
    line-height: 1.67;
  }
}
.service__content-info {
  margin-top: 80px;
}
.service__content-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.service__content-info-item:last-child {
  margin-bottom: 0;
}
.service__content-info-item .info-box {
  flex: 0 0.5 400px;
  height: 200px;
  border-radius: 20px;
  border: 2px solid transparent;
  background-image: linear-gradient(#000, #000), linear-gradient(300deg, #e657af, #0049ff, #43cf2e);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.service__content-info-item .info-box-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  font-size: 20px;
  line-height: 1.6;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(to right, #f00, rgba(0, 0, 0, 0));
  background: linear-gradient(90deg, #43cf2e, #0049ff, #e657af);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (min-width: 751px) {
  .service__content-info-item .info-box-item {
    font-size: 24px;
    line-height: 1.42;
  }
}
@media screen and (min-width: 1025px) {
  .service__content-info-item .info-box-item {
    font-size: 24px;
    line-height: 1.42;
  }
}
@media screen and (min-width: 1441px) {
  .service__content-info-item .info-box-item {
    font-size: 28px;
    line-height: 1.43;
  }
}
.service__content-info-item .info-title {
  margin-left: 52px;
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 751px) {
  .service__content-info-item .info-title {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .service__content-info-item .info-title {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .service__content-info-item .info-title {
    font-size: 24px;
    line-height: 1.67;
  }
}
.service-slide {
  margin-top: 160px;
}
.service-slide__title {
  font-size: 32px;
  line-height: 1.06;
  font-weight: bold;
  color: #fff;
  font-weight: 600;
}
@media screen and (min-width: 751px) {
  .service-slide__title {
    /*   font-size: 28px;
      line-height: 1.43; */
    font-size: 40px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1025px) {
  .service-slide__title {
    /*    font-size: 40px;
       line-height: 1.25; */
    font-size: 54px;
    line-height: 1.19;
  }
}
@media screen and (min-width: 1441px) {
  .service-slide__title {
    font-size: 60px;
    line-height: 1;
  }
}
.service-slide__box {
  margin-top: 80px;
}
.service-slide__item {
  flex: 0 1 300px;
  margin-right: 50px;
}
.service-slide__item:last-child {
  margin-right: 0;
}
.service-slide__item > a {
  display: block;
}
.service-slide__item-title {
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 751px) {
  .service-slide__item-title {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .service-slide__item-title {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .service-slide__item-title {
    font-size: 24px;
    line-height: 1.67;
  }
}
.service-slide__item img {
  width: 100%;
}
.service__footer {
  position: relative;
  height: calc(var(--full-height, 100vh) * 2);
  margin-top: calc(var(--full-height, 100vh) * -1);
}
.service__footer[data-full-step="1"] .service__main--title-card::before {
  /*       content: '';
        display: block;
        background-image: url(/assets/img/card/card-glow.png);
        background-repeat: no-repeat;
        width: 160%;
        height: 160%;
        left: -30%;
        top: -30%;
        position: absolute;
        background-size: cover; */
  /* animation: card-grow 1s linear forwards paused; */
  /*  opacity: 1; */
}
.service__footer .inner {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  height: calc(var(--full-height, 100vh) * 1);
  padding: 0 100px;
  overflow: hidden;
}
.service__footer .service__main--title-card {
  opacity: 1;
}
.service__footer .service__main--title-card .card-text {
  background: #000;
  border-radius: 20px;
}
.service__footer .service__main--title-card::before {
  content: "";
  display: block;
  background-image: url(/assets/img/card/card-glow.png);
  background-repeat: no-repeat;
  width: 160%;
  height: 160%;
  left: -30%;
  top: -30%;
  position: absolute;
  background-size: cover;
  /*  opacity: 0; */
  animation: card-grow 1s linear forwards paused;
  animation-delay: calc(var(--progress-full) * -1s);
}
.service__footer[data-scradar-start="0"][data-scradar-end="1"] .service__main--title-card {
  opacity: 0;
}
.service__footer .left {
  width: 400px;
}
.service__footer p {
  padding-left: 200px;
  font-size: 72px;
  font-weight: 700;
  line-height: 1.28;
  color: #fff;
  opacity: calc(var(--progress-full) * 2.5);
}

@media (min-width: 751px) {
  .slide--service-code {
    height: max(var(--full-height, 100vh) * 2 + var(--scroll-width) * 1px, var(--full-height, 100vh));
  }
  .slide--service-code[data-scradar-end="0"] {
    opacity: 0;
  }
  .slide--service-code .icon-card-img {
    opacity: calc(1 - 5 * (var(--progress-full) - 0.4));
  }
  .slide--service-code .icon-card-img-active {
    opacity: calc(5 * (var(--progress-full) - 0.6));
  }
  .slide--service-code .slide__inner {
    transform: translate3d(calc((var(--scroll-width) * 1px + var(--space) * 1px) * min(var(--progress-full), 0.4) * -2.5 + (var(--body-scroll-width) * 1px + var(--space) * 1px) * (clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5), 0, 0);
  }
  .slide--service-code .slide__inner .card__item:nth-child(2) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -440px * (2 - 1)), 0, 0);
  }
  .slide--service-code .slide__inner .card__item:nth-child(3) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -440px * (3 - 1)), 0, 0);
  }
  .slide--service-code .slide__inner .card__item:nth-child(4) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -440px * (4 - 1)), 0, 0);
  }
  .slide--service-code[data-full-step="2"] .slide__inner .icon-card-img-active {
    opacity: calc(1 - (max(var(--progress-full), 0.8) - 0.8) * 5);
  }

  .slide--service-code .card__item-box {
    background-color: #000;
  }

  .slide--service {
    height: max(var(--full-height, 100vh) * 2 + var(--scroll-width) * 1px, var(--full-height, 100vh));
    margin-top: calc(var(--full-height, 100vh) * -1);
  }
  .slide--service[data-scradar-start="0"][data-scradar-end="1"] .card__item:first-child {
    opacity: 0;
  }
  .slide--service[data-scradar-start="1"][data-scradar-end="1"] .card__item:first-child {
    opacity: 1;
  }
  .slide--service[data-scradar-start="1"][data-scradar-end="0"] {
    opacity: 0;
  }
  .slide--service .card__item-box {
    background-color: #000;
  }
  .slide--service .slide__inner {
    transform: translate3d(calc(var(--scroll-width) * 1px * min(var(--progress-full), 0.4) * -2.5 + var(--body-scroll-width) * 1px * (clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5), 0, 0);
  }
  .slide--service .slide__inner .card__item:nth-child(1) {
    z-index: calc(4 - 1);
  }
  .slide--service .slide__inner .card__item:nth-child(2) {
    z-index: calc(4 - 2);
  }
  .slide--service .slide__inner .card__item:nth-child(3) {
    z-index: calc(4 - 3);
  }
  .slide--service .slide__inner .card__item:nth-child(4) {
    z-index: calc(4 - 4);
  }
  .slide--service .slide__inner .card__item:nth-child(2) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -440px * (2 - 1)), 0, 0);
  }
  .slide--service .slide__inner .card__item:nth-child(3) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -440px * (3 - 1)), 0, 0);
  }
  .slide--service .slide__inner .card__item:nth-child(4) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -440px * (4 - 1)), 0, 0);
  }

  .slide--service[data-full-step="2"] {
    opacity: 1;
  }
}
@media (max-width: 1440px) {
  .service__container {
    padding: 0 72px;
  }
  .service__container[data-scradar-start="1"][data-scradar-end="1"] .service__main--title {
    position: fixed;
  }
  .service__container[data-scradar-start="1"][data-scradar-end="1"] .service__main--area {
    margin-left: 303px;
  }
  .service__main--title {
    width: 303px;
  }
  .service__main--title-card {
    width: 303px;
    height: 408px;
  }
  .service__main--area {
    padding: calc(var(--full-height, 100vh) * 1) 0 calc(var(--full-height, 100vh) * 1) 8.5417vw;
    flex: 0 0 calc(100% - 303px);
    max-width: calc(100% - 303px);
  }
  .service__content--description {
    margin-top: 24px;
  }
  .service__content-info {
    margin-top: 48px;
  }
  .service__content-info-item {
    margin-bottom: 36px;
  }
  .service__content-info-item:last-child {
    margin-bottom: 0;
  }
  .service__content-info-item .info-box {
    flex-basis: 340px;
    height: 170px;
  }
  .service__content-info-item .info-title {
    margin-left: 52px;
  }
  .service-slide {
    margin-top: 96px;
  }
  .service-slide__box {
    margin-top: 48px;
  }
  .service-slide__item {
    flex: 0 1 240px;
    margin-right: 27px;
  }
  .service-slide__item:last-child {
    margin-right: 0;
  }

  .slide--service-code .slide__inner .card__item:nth-child(2) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (2 - 1)), 0, 0);
  }
  .slide--service-code .slide__inner .card__item:nth-child(3) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (3 - 1)), 0, 0);
  }
  .slide--service-code .slide__inner .card__item:nth-child(4) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (4 - 1)), 0, 0);
  }

  .slide--service .slide__inner .card__item:nth-child(2) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (2 - 1)), 0, 0);
  }
  .slide--service .slide__inner .card__item:nth-child(3) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (3 - 1)), 0, 0);
  }
  .slide--service .slide__inner .card__item:nth-child(4) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (4 - 1)), 0, 0);
  }

  .service__footer .inner {
    padding: 0 72px;
  }
  .service__footer .left {
    width: 303px;
  }
  .service__footer p {
    padding-left: 120px;
    font-size: 54px;
    font-weight: 700;
    line-height: 1.28;
    color: #fff;
    opacity: calc(var(--progress-full) * 2.5);
  }
}
@media (max-width: 1024px) {
  .service__container {
    padding: 0 60px;
  }
  .service__container[data-scradar-start="1"][data-scradar-end="1"] .service__main--title {
    position: fixed;
  }
  .service__container[data-scradar-start="1"][data-scradar-end="1"] .service__main--area {
    margin-left: 210px;
  }
  .service__main--title {
    width: 210px;
  }
  .service__main--title .service__main--title {
    width: 210px;
  }
  .service__main--title .service__main--title-card {
    width: max(210px + (var(--progress-full) - 0.8) * 465px, 210px);
    height: max(284px + (var(--progress-full) - 0.8) * 620px, 284px);
  }
  .service__main--area {
    padding: calc(var(--full-height, 100vh) * 1) 0 calc(var(--full-height, 100vh) * 1) 7.7083vw;
    flex: 0 0 calc(100% - 210px);
    max-width: calc(100% - 210px);
  }
  .service__content--description {
    margin-top: 20px;
  }
  .service__content-info {
    margin-top: 40px;
  }
  .service__content-info-item {
    display: block;
    margin-bottom: 24px;
  }
  .service__content-info-item:last-child {
    margin-bottom: 0;
  }
  .service__content-info-item .info-box {
    flex-basis: 340px;
    max-width: 340px;
    height: 150px;
  }
  .service__content-info-item .info-title {
    margin: 12px 0 0;
  }
  .service-slide {
    margin-top: 80px;
  }
  .service-slide__box {
    margin-top: 40px;
  }
  .service-slide__item {
    flex: 0 1 158px;
    margin-right: 24px;
  }
  .service-slide__item:last-child {
    margin-right: 0;
  }

  .slide--service-code .slide__inner {
    transform: translate3d(calc((var(--scroll-width) * 1px + 120px) * min(var(--progress-full), 0.4) * -2.5 + (var(--body-scroll-width) * 1px + 120px) * (clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5), 0, 0);
  }
  .slide--service-code .slide__inner .card__item:nth-child(2) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (2 - 1)), 0, 0);
  }
  .slide--service-code .slide__inner .card__item:nth-child(3) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (3 - 1)), 0, 0);
  }
  .slide--service-code .slide__inner .card__item:nth-child(4) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (4 - 1)), 0, 0);
  }

  .slide--service-code[data-full-step="2"] .card__item-box {
    width: calc(303px - (var(--progress-full) - 0.8) * 465px);
    height: calc(408px - (var(--progress-full) - 0.8) * 620px);
  }

  .service__footer .inner {
    padding: 0 60px;
  }
  .service__footer p {
    padding-left: 100px;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.28;
    color: #fff;
    opacity: calc(var(--progress-full) * 2.5);
  }
}
@media (max-width: 750px) {
  .service__container {
    display: block;
    margin-top: calc(var(--full-height, 100vh) * -1.2);
    padding: 0 24px;
  }
  .service__container[data-scradar-start="1"][data-scradar-end="0"] {
    opacity: 1;
  }
  .service__container[data-scradar-start="1"][data-scradar-end="1"] .service__main--title {
    position: relative;
  }
  .service__container[data-scradar-start="1"][data-scradar-end="1"] .service__main--area {
    margin-left: 0;
  }
  .service__footer {
    height: calc(var(--full-height, 100vh) * 5);
    margin-top: calc(var(--full-height, 100vh) * -1.25);
  }
  .service__footer .inner {
    display: block;
    padding: 12.3153vh 24px 0;
  }
  .service__footer .inner .left {
    width: 100%;
    opacity: calc((var(--progress-full) - 0.25) * 5);
  }
  .service__footer .inner p {
    padding: 70px 0 0;
    opacity: calc((var(--progress-full) - 0.1) * 4 - 0.02);
  }
}
@media (max-width: 750px) and (max-width: 375px) and (min-aspect-ratio: 1/2) {
  .service__footer .inner p {
    padding-top: 28px;
    font-size: 28px;
    line-height: 1.36;
  }
  .service__footer .inner p br {
    display: none;
  }
}
@media (max-width: 750px) {
  .service .service__main--title {
    display: block;
    position: relative;
    width: 100%;
    height: max(var(--full-height, 100vh) + var(--scroll-width) * 1px, var(--full-height, 100vh));
    margin-top: 0;
  }
  .service .service__main--title > div {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--full-height, 100vh) * 1);
  }
  .service .service__main--title p {
    display: block;
    padding: 13.7931vh 0 36px;
    font-size: 44px;
    line-height: 1.36;
    font-weight: 700;
  }
}
@media (max-width: 750px) and (max-width: 375px) and (min-aspect-ratio: 1/2) {
  .service .service__main--title p {
    padding: 10.8374vh 0 28px;
    font-size: 28px;
    line-height: 1.36;
  }
  .service .service__main--title p br {
    display: none;
  }
  .service .service__main--title p br ~ br {
    display: inline;
  }
}
@media (max-width: 750px) {
  .service .service__main--title-card {
    width: calc(min(var(--window-width), 430) * 1px - 48px);
    height: auto;
    margin: auto;
    transform: translateX(calc((var(--window-width) * 1px / 2 - min(var(--window-width), 430) * 1px / 2) * (1 - min(var(--progress-full), 0.2) * 5)));
  }
  .service .service__main--title-card::before {
    animation: card-grow-mb 1s linear forwards paused;
    animation-delay: calc(var(--progress-full) * -1s);
    /*   opacity: calc(var(--progress-full) * -1s); */
  }
  .service .service__main--title-card > div {
    position: relative;
    width: 100%;
    height: 0;
    margin: 0 auto;
    padding-top: 132%;
  }
  .service .service__main--title-card .card-text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 750px) {
  .service__main--area {
    padding: 10.8374vh 0 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 750px) {
  .service__content--description {
    margin-top: 24px;
  }
  .service__content--description br {
    display: none;
  }
  .service__content-info {
    margin-top: 60px;
  }
  .service__content-info-item {
    display: block;
    margin-bottom: 36px;
  }
  .service__content-info-item:last-child {
    margin-bottom: 0;
  }
  .service__content-info-item .info-box {
    max-width: none;
    width: 100%;
    height: 112px;
  }
  .service__content-info-item .info-box-item {
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 1.6;
    font-weight: bold;
    /* line-height: 1.43; */
    text-align: center;
    background: linear-gradient(to right, #f00, rgba(0, 0, 0, 0));
    background: linear-gradient(90deg, #43cf2e, #0049ff, #e657af);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) and (min-width: 751px) {
  .service__content-info-item .info-box-item {
    font-size: 24px;
    line-height: 1.42;
  }
}
@media screen and (max-width: 750px) and (min-width: 1025px) {
  .service__content-info-item .info-box-item {
    font-size: 24px;
    line-height: 1.42;
  }
}
@media screen and (max-width: 750px) and (min-width: 1441px) {
  .service__content-info-item .info-box-item {
    font-size: 28px;
    line-height: 1.43;
  }
}
@media (max-width: 750px) {
  .service__content-info-item .info-title {
    margin-left: 0;
  }
}
@media (max-width: 750px) {
  .service__content-slide {
    margin-top: 60px;
  }
  .service__content-slide-title {
    font-size: 32px;
    color: #fff;
    line-height: 1.02;
    font-weight: 600;
  }
  .service__content-slide-box {
    padding: 32px 0 80px;
  }
  .service__content-slide-list {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .service__content-slide-list .service-slide__item {
    width: 60%;
  }
  .service__content-slide-list .service-slide__item-img--img img {
    width: 100%;
  }
  .service__content-slide-list .service-slide__item-title {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    color: #fff;
    margin-top: 9px;
  }
  .service__content-slide-list .swiper__navi {
    margin-top: 19px;
  }
  .service__content-slide-list .slide__btn {
    display: flex;
    justify-content: end;
    margin-top: 32px;
  }
  .service__content-slide-list .slide__btn--item {
    width: 28px;
    height: 28px;
    margin-left: 16px;
  }
  .service__content-slide-list .slide__btn-prev {
    background: url(/assets/img/service/icon-prev-on.svg) no-repeat center/cover;
  }
  .service__content-slide-list .slide__btn-prev.swiper-button-disabled {
    background: url(/assets/img/service/icon-prev.svg) no-repeat center/cover;
  }
  .service__content-slide-list .slide__btn-next {
    background: url(/assets/img/service/icon-next-on.svg) no-repeat center/cover;
  }
  .service__content-slide-list .slide__btn-next.swiper-button-disabled {
    background: url(/assets/img/service/icon-next.svg) no-repeat center/cover;
  }
}
@media (max-width: 750px) {
  .service .service-slide__item {
    flex: 1 0 auto;
  }
}
@media (max-width: 750px) {
  .slide--service-code {
    height: calc(var(--full-height, 100vh) * 5);
  }
  .slide--service-code[data-scradar-start="1"][data-scradar-end="0"] {
    opacity: 0;
  }
  .slide--service-code .slide__inner {
    transform: none !important;
  }
  .slide--service-code .slide__item {
    transform: translate3d(calc((var(--scroll-width) * 1px + 48px) * min(var(--progress-full), 0.4) * -2.5), 0, 0);
  }
  .slide--service-code .slide__item .card__item:nth-child(2) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (2 - 1)), 0, 0);
  }
  .slide--service-code .slide__item .card__item:nth-child(3) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (3 - 1)), 0, 0);
  }
  .slide--service-code .slide__item .card__item:nth-child(4) {
    transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (4 - 1)), 0, 0);
  }

  .slide--service-code .slide__body .icon-card-img {
    opacity: calc(1 - 5 * (var(--progress-full) - 0.4));
  }
  .slide--service-code .slide__body .icon-card-img-active {
    opacity: calc(5 * (var(--progress-full) - 0.4));
  }
  .slide--service-code[data-full-step="1"] .slide__body {
    opacity: calc(1 - (max(var(--progress-full), 0.6) - 0.6) * 5);
  }

  .slide--service-code[data-full-step="2"] .slide__body {
    opacity: 0;
  }

  .slide--service-code .card__item {
    width: calc(min(var(--window-width), 430) * 1px - 48px);
    height: calc((min(var(--window-width), 430) * 1px - 48px) * 1.32);
    padding: 0;
    transform: none !important;
    margin-right: 32px;
  }
  .slide--service-code .card__item:last-child {
    margin-right: 0 !important;
  }
  .slide--service-code .card__item-box {
    width: 100% !important;
    height: 100% !important;
  }
}
@media (max-width: 750px) {
  .slide--service {
    height: calc(var(--full-height, 100vh) * 5);
  }
  .slide--service .slide__wrap {
    padding-top: 12.3153vh;
  }
  .slide--service .slide__wrap .slide__item {
    margin: 0 auto;
  }
  .slide--service .slide__wrap .slide__inner {
    transform: none;
  }
  .slide--service .slide__wrap .slide__inner .card__item {
    margin-right: 0;
    opacity: calc(1 - (max(var(--progress-full), 0.8) - 0.8) * 5);
  }
  .slide--service .slide__wrap .slide__inner .card__item:nth-child(2) {
    transform: none;
  }
  .slide--service .slide__wrap .slide__inner .card__item:nth-child(3) {
    transform: translate3d(0, calc(min(var(--progress-full), 0.4 * (3 - 2)) * 2.5 * ((min(var(--window-width), 430) * -1px + 48px) * 1.32 - 32px)), 0) !important;
  }
  .slide--service .slide__wrap .slide__inner .card__item:nth-child(4) {
    transform: translate3d(0, calc(min(var(--progress-full), 0.4 * (4 - 2)) * 2.5 * ((min(var(--window-width), 430) * -1px + 48px) * 1.32 - 32px)), 0) !important;
  }
  .slide--service[data-full-step="2"] .slide__inner .card__item:nth-child(2), .slide--service[data-full-step="2"] .slide__inner .card__item:nth-child(3) {
    opacity: 0;
  }
}
@media (max-width: 430px) {
  .slide--service-code[data-full-step="1"] .slide__body {
    opacity: 1;
  }
  .slide--service-code[data-full-step="1"] .slide__body .icon-card-img-active {
    transition: opacity 0.5s;
  }

  .slide--service-code[data-full-step="2"] .slide__body {
    opacity: 1;
  }
  .slide--service-code[data-full-step="2"] .slide__body .icon-card-img-active {
    opacity: 0;
    transition: opacity 0.5s;
  }
}
/* 영문 */
[lang=en] .service {
  /* mobile */
}
@media (max-width: 750px) {
  [lang=en] .service {
    margin-top: 10.8374vh;
  }
  [lang=en] .service .service__content--title {
    font-size: 40px;
    line-height: 1.35;
  }
}
* {
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6 {
  font-family:'Archivo', 'NotoSansCJKkr';
}
body {
  font-family: 'Archivo','NotoSansCJKkr';
  margin: 0;
  word-break: keep-all;
  overflow-x: hidden;
  transition: background-color 0.5s;
}
body.dark {
  background-color: #000;
}
[lang="en"] {
  h1, h2, h3, h4, h5, h6, body {
    font-family: 'Archivo';
  }
}
body.scroll-none {
  overflow: hidden;
}
p {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input,
select,
textarea,
button,
a {
  margin: 0;
  border-width: 0;
  background: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
input:focus {
  outline: none;
}
input[type=number] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
}
.font-archivo {
  font-family: 'Archivo';
}
.font-notosans {
  font-family: 'NotoSansCJKkr';
}
@import '../abstracts/mixins';

@keyframes creator-intro {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
/*   75% {
    opacity: 1;
  } */
  80% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  } 
}
@keyframes creator_arrow{
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  } 
}
.creator {
  &__content {
    position: relative;
    height: calc(var(--full-height, 100vh) * 2.2);
  }
  &__inner {
    position: sticky;
    top: 0;
    width: 100%;
    height: var(--full-height, 100vh);
  }
  &__img {
    position: relative;
    height: 100%;
    picture {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      img {
        position: absolute;
        width: 100vw;
        height: var(--full-height, 100vh);
        max-width: none;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
  }

  &__intro {
    position: absolute;
    left: 200px;
    top: 50%;
    transform: translate(0, -50%);
    color: #fff;
    animation: creator-intro 1s linear forwards paused;
    animation-delay: calc(var(--progress-full) * -1s);
 
    &--text {
      @include font-size(60px);
      font-weight: 600;
      line-height: 1.2 !important;
    }

    &--description {
      margin-top: 26px;
      &-text { 
        @include font-size(24px);
        font-weight: 600;
        line-height: 1.67;
        margin:  0;

        br {
          display: none;
        }
      }
    }

    &--detail {
      margin-top: 24px;

      &-button {
        position: relative;
        display: inline-block;
        font-size: 18px;
        line-height: 1.56;
        font-weight: 600;
        color: #fff;
        border: 1px solid #fff;
        padding: 9px 16px 9px 38px;
        text-decoration: none;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 16px;
          top: 50%;
          margin-top: -7px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 0 7px 12px;
          border-color: transparent transparent transparent #fff;
        }
      }
    }
  }

  &__scroll-down {
    position: absolute;
    bottom: 40px;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.5s;

    @at-root .creator__content[data-scradar-start="1"][data-scradar-end="1"]{
      .creator__scroll-down {
        opacity: 1;
        animation: creator_arrow 1s linear forwards paused;
        animation-delay: calc(var(--progress-full) * -1s);
      }
    }
  }

  &__slide {
    margin-top: 10.8374vh;

    .card__item-head-title {
      line-height: 1.21 !important;
    }
  }
}

@media (max-width: 1440px) {
  .creator {
    &__intro {
      left: 150px;

      &--detail {
        &-button {
          font-size: 14px;
          line-height: 1.71;
          padding: 7px 14px 7px 33px;

          &::before {
            left: 14px;
          }
        }
      }
    }
  }
}
 
@media (max-width: 1024px) {
  .creator {
    &__intro {
      left: 80px;

      &--description {
        margin-top: 20px;
      }
    }
  }
}
 

@media (max-width: 750px) { 
  .creator {
    &__intro {
      padding: 10.8374vh 24px;
      left: 0px;
      top: 0px;
      transform: translate(0);
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
   
      &--text {
        font-size: 44px;
        line-height: 1.36 !important;
      }
  
      &--description {
        /* margin-top: auto;
        order: 2; */
        margin-top: 14px;
        &-text { 
          // line-height: 1.7;

          br {
            display: block;
          }
        }
      }
  
      &--detail {
        margin-top: 20px;
  
        &-button {
          font-size: 14px;
          line-height: 1.71;
          border: 1.2px solid #fff;
          padding: 8px 12px 6px 28px;

          &::before {
            left: 12px;
            margin-top: -6px;
            border-width: 6px 0 6px 10px;
          }
        }
      }
    }

    &__slide {
      .card__item-head-title {
        font-size: 34px;
        line-height: 1.29 !important;
      }
  
      .card__item {
        width: calc(min(var(--window-width), 430) * 1px - 48px);
        height: calc((min(var(--window-width), 430) * 1px - 48px) * 1.32);
        margin-right: 32px;
        padding-top: 0;
        &:last-child {
          margin-right: 0;
        }
      }

      .slide__bottom {
        .slide__description {
          padding: 40px 0 0;
        }
      }
    }
  }
}

/* 영문 */
[lang="en"] .creator {
  /* mobile */
  @media (max-width: 750px) {
    .slide__description {
      br {
        display: none;
      }
    }
  }
}
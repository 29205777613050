@mixin font-size($fs-font) {
  // 100 !!
  @if $fs-font == 100px {
    font-size: 60px;
    line-height: 1.2;
    @media screen and (min-width: 751px) {
      font-size: 50px;
      line-height: 1;
    }
    @media screen and (min-width: 1025px) {
      font-size: $fs-font;
      line-height: 1;
    }
  }
  // 72
  @else if $fs-font == 72px {
    font-size: 44px;
    line-height: 1.36;
    @media screen and (min-width: 751px) {
      font-size: 40px;
      line-height: 1.35;
    }
    @media screen and (min-width: 1025px) {
      font-size: 54px;
      line-height: 1.33;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
      line-height: 1.28;
    }
  }
  // 60 !!
  @else if $fs-font == 60px {
    font-size: 32px;
    line-height: 1.06;
    @media screen and (min-width: 751px) {
    /*   font-size: 28px;
      line-height: 1.43; */
      font-size: 40px;
      line-height: 1.25;
    }
    @media screen and (min-width: 1025px) {
   /*    font-size: 40px;
      line-height: 1.25; */
      font-size: 54px;
      line-height: 1.19;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
      line-height: 1;
    }
  }
  // 52!!
  @else if $fs-font == 52px {
    font-size: 36px;
    line-height: 1.33;
    @media screen and (min-width: 751px) {
      font-size: 26px;
      line-height: 1.35;
    }
    @media screen and (min-width: 1025px) {
      font-size: $fs-font;
      line-height: 1.35;
    }
  }
  // 50 !!
  @else if $fs-font == 50px {
    font-size: 34px;
    line-height: 1.29;
    @media screen and (min-width: 751px) {
      font-size: 36px;
      line-height: 1;
    }
    @media screen and (min-width: 1025px) {
      font-size: 36px;
      line-height: 1;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
      line-height: normal;
    }
  }
  // 40 !
  @else if $fs-font == 40px {
    font-size: 40px;
    line-height: 1.25;
    @media screen and (min-width: 751px) {
      font-size: 24px;
      line-height: 1.25;
    }
    @media screen and (min-width: 1025px) {
      font-size: 36px;
      line-height: 1.25;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
      line-height: 1.25;
    }
  }
  // 28 !!
  @else if $fs-font == 28px {
    font-size: 20px;
    line-height: 1.6;
    @media screen and (min-width: 751px) {
      font-size: 24px;
      line-height: 1.42;
    }
    @media screen and (min-width: 1025px) {
      font-size: 24px;
      line-height: 1.42;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
      line-height: 1.43;
    }
  }
  // 24
  @else if $fs-font == 24px {
    font-size: 16px;
    line-height: 1.63;
    @media screen and (min-width: 751px) {
      font-size: 16px;
      line-height: 1.75;
    }
    @media screen and (min-width: 1025px) {
      font-size: 18px;
      line-height: 1.89;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
      line-height: 1.67;
    }
  }
  // 20 !!
  @else if $fs-font == 20px {
    font-size: 20px;
    @media screen and (min-width: 751px) {
      font-size: 14px;
    }
    @media screen and (min-width: 1025px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
    }
  }
  // 16 !!
  @else if $fs-font == 16px {
    font-size: 16px;
    line-height: 1.63;
    @media screen and (min-width: 751px) {
      font-size: 14px;
      line-height: 1.63;
    }
    @media screen and (min-width: 1025px) {
      font-size: 14px;
      line-height: 1.63;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
      line-height: 1.63;
    }
  }
  @else if $fs-font == 14px {
    font-size: 12px;
    line-height: 1.63;
    @media screen and (min-width: 751px) {
      font-size: 14px;
      line-height: 1.63;
    }
    @media screen and (min-width: 1025px) {
      font-size: 14px;
      line-height: 1.63;
    }
    @media screen and (min-width: 1441px) {
      font-size: $fs-font;
      line-height: 1.63;
    }
  }
  font-weight: bold;
}
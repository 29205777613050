@charset "UTF-8";
.ground-section {
  padding: 0 100px;
  /* 1440 */
  /* 1024 */
}
@media (max-width: 1440px) {
  .ground-section {
    padding: 0 72px;
  }
}
@media (max-width: 1024px) {
  .ground-section {
    padding: 0 60px;
  }
}

.ground__container {
  padding: 200px 0;
}
.ground__content-title {
  font-size: 44px;
  line-height: 1.36;
  font-weight: bold;
  font-weight: 600;
  color: #000;
  margin-bottom: 34px;
}
@media screen and (min-width: 751px) {
  .ground__content-title {
    font-size: 40px;
    line-height: 1.35;
  }
}
@media screen and (min-width: 1025px) {
  .ground__content-title {
    font-size: 54px;
    line-height: 1.33;
  }
}
@media screen and (min-width: 1441px) {
  .ground__content-title {
    font-size: 72px;
    line-height: 1.28;
  }
}
.ground__content-title-main {
  display: block;
}
.ground__content-title-marquee {
  display: none;
}
.ground__content-desc {
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 600;
  color: #000;
}
@media screen and (min-width: 751px) {
  .ground__content-desc {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .ground__content-desc {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .ground__content-desc {
    font-size: 24px;
    line-height: 1.67;
  }
}
.ground__content-desc span {
  display: block;
}
.ground__button-wrap {
  margin-top: 70px;
  display: flex;
}
.ground__button-item {
  font-size: 18px;
  line-height: 2.22;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid #000;
  padding: 3px 21px 1px;
  display: block;
  margin-right: 18px;
}
.ground__button-item:last-child {
  margin-right: 0;
}
.ground__button-download {
  background: #000;
  color: #fff;
}
.ground__button-link {
  color: #000;
}

@media (max-width: 750px) {
  .ground-section {
    padding: 0 24px;
  }

  .ground__container {
    padding: 126px 0 176px;
  }
  .ground__content-box {
    overflow: hidden;
    margin-left: -24px;
    margin-right: -24px;
  }
  .ground__content-title {
    line-height: 1.36;
    margin-bottom: 24px;
    /* 타이틀 마퀴 */
  }
  .ground__content-title-main {
    display: none;
  }
  .ground__content-title-marquee {
    display: block;
    overflow: hidden;
  }
  .ground__content-title-marquee .title-marquee-inner {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    min-width: 100%;
  }
  .ground__content-title-marquee .title-marquee-sign {
    display: inline-block;
    margin-right: 8px;
    font-size: 40px;
  }
  .ground__content-desc {
    max-width: 430px;
    font-size: 16px;
  }
  .ground__content-desc span {
    display: inline-block;
  }
  .ground__button-wrap {
    margin-top: 24px;
    flex-wrap: wrap;
  }
  .ground__button-item {
    font-size: 14px;
    line-height: 1.71;
    border: 1.2px solid #000;
    padding: 8px 16px;
    margin-right: 6px;
    margin-bottom: 8px;
  }
}
/* 영문 */
[lang=en] .ground-section {
  /* mobile */
}
@media (max-width: 750px) {
  [lang=en] .ground-section .ground__content-desc span {
    display: inline;
  }
}

/* join */
.join {
  transition: 0.6s ease-in-out;
  transition-delay: 0.4s;
  transform: translate(0, 100%);
}
.join.active {
  transform: translate(0, 0);
}
.join__content {
  overflow-x: hidden;
  position: relative;
  margin-left: -700px;
}
.join__content-inner {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: 100%;
}
.join__content-inner-box {
  display: flex;
  position: relative;
}
.join__content-inner-box:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(36px);
}
.join__content-list:nth-child(3n-1) {
  background: #0049ff;
}
.join__content-list:nth-child(3n-2) {
  background: #00bf41;
}
.join__content-list:nth-child(3n) {
  background: #e657af;
}
.join__content-item {
  position: relative;
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 1.63;
  font-weight: bold;
  font-weight: 600;
  padding: 10px 72px;
  white-space: nowrap;
  z-index: 2;
}
@media screen and (min-width: 751px) {
  .join__content-item {
    font-size: 16px;
    line-height: 1.75;
  }
}
@media screen and (min-width: 1025px) {
  .join__content-item {
    font-size: 18px;
    line-height: 1.89;
  }
}
@media screen and (min-width: 1441px) {
  .join__content-item {
    font-size: 24px;
    line-height: 1.67;
  }
}
.join__content-item-italic {
  font-weight: 600;
  font-style: italic;
}

@media (max-width: 750px) {
  .join__content {
    margin-left: -200vw;
  }
  .join__content-item {
    font-size: 16px;
    line-height: 2.5;
    padding: 4px 18px;
  }
  .join__content-item-italic {
    font-weight: 600;
    font-style: italic;
  }
}
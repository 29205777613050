/* feature */
.feature {
  position: relative;
  overflow: hidden;
  /* pc */
  /* mobile */
}
.feature__content {
  position: relative;
  height: 175px;
}
.feature__content:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  height: calc(100% - 2px);
  width: 100%;
  backdrop-filter: blur(50px);
  outline: 1px solid #000;
  opacity: 0;
  transition: opacity 0.3s;
}
.feature__item {
  padding: 45px 0;
  text-align: center;
  color: #fff;
}
.feature__item:nth-child(1) {
  background-color: #e657af;
}
.feature__item:nth-child(2) {
  background-color: #0049ff;
}
.feature__item:nth-child(3) {
  background-color: #00bf41;
}
.feature__item--inner {
  position: relative;
  z-index: 10;
  opacity: calc((var(--progress-visible) - 0.3) / 0.15);
  transition: opacity 0.3s;
}
.feature__item--title {
  font-family: "Archivo";
  font-size: 60px;
  font-weight: 600;
  line-height: 1;
}
.feature__item--subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
}
.feature__title {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 100px;
  /* pc */
  transform: translateY(-50%);
  text-align: center;
  font-size: 40px;
  line-height: 1.25;
  font-weight: bold;
  font-weight: 600;
  line-height: 1.25;
  color: #fff;
  opacity: calc((var(--progress-visible) - 0.52) / 0.15);
}
@media (min-width: 751px) and (max-width: 1024px) {
  .feature__title {
    padding: 0 60px;
  }
}
@media screen and (min-width: 751px) {
  .feature__title {
    font-size: 24px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1025px) {
  .feature__title {
    font-size: 36px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1441px) {
  .feature__title {
    font-size: 40px;
    line-height: 1.25;
  }
}
.feature[data-visible-step="1"] .feature__content:after {
  opacity: 1;
}
.feature[data-visible-step="1"] .feature__item--inner {
  opacity: 0;
}
@media (min-width: 751px) {
  .feature__content {
    display: flex;
  }
  .feature__item {
    width: 33.333%;
  }
  .feature__item:nth-child(-n+2) {
    transform: translateX(max(min(200% * min(var(--progress-visible) - 0.3, 1), 0.0001%), -50%));
  }
  .feature__item:nth-child(3) {
    transform: translateX(max(min(-200% * min(var(--progress-visible) - 0.3, 0), 50%), 0.0001%));
  }
  .feature__item--title {
    font-size: 30px;
  }
  .feature__title br {
    display: none;
  }
}
@media (min-width: 1025px) {
  .feature__item--title {
    font-size: 45px;
  }
}
@media (min-width: 1441px) {
  .feature__item--title {
    font-size: 60px;
  }
  .feature__item--subtitle {
    font-size: 16px;
  }
}
@media (max-width: 750px) {
  .feature__content {
    height: 360px;
  }
  .feature__content:after {
    outline: none;
  }
  .feature__item {
    padding: 0;
    position: relative;
    height: 25px;
    height: min(90% * max(var(--progress-visible) - 0.045, 0), 33.333%);
  }
  .feature__item:nth-child(1) {
    height: 33.333%;
  }
  .feature__item--inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    opacity: calc((var(--progress-visible) - 0.3) / 0.1);
  }
  .feature__item--title {
    font-size: 40px;
    line-height: 1.1;
  }
  .feature__title {
    padding: 0 24px;
    font-size: 24px;
    line-height: 1.42;
    opacity: calc((var(--progress-visible) - 0.52) / 0.1);
  }
}
@import '../abstracts/mixins';
@keyframes card-grow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {

    opacity: 1;
  }
}
@keyframes card-grow-mb {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.text-gradient {
  background-image: linear-gradient(90deg, #43cf2e ,#0049ff ,#e657af  );
  background-size: cover;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}
.mobile-br {
  display: none;
}
.pc-br {
  display: block;
}
@media (max-width: 750px) { 
  .mobile-br {
    display: block;
  }
  .pc-br {
    display: none;
  }
}

body:not(.dark) {
  .service {
    * {
      color: #000 !important;
    }
  }
}


.service {
  position: relative;
  &__container {
    display: flex;
    margin-top: calc(var(--full-height, 100vh) * -1);
    padding: 0 100px;
    &[data-scradar-start="0"][data-scradar-end="1"],
    &[data-scradar-start="1"][data-scradar-end="0"] {
      opacity: 0;
    }
    &[data-scradar-start="1"][data-scradar-end="1"] {
      opacity: 1;
    }
    &[data-scradar-start="1"][data-scradar-end="1"] {
      .service__main--title {
        position: fixed;
      }
      .service__main--area {
        margin-left: 400px; 
      }
    }
  }
  &__main {
    &--title {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      width: 400px;
      height: calc(var(--full-height, 100vh) * 1);
      color: #fff;
      .card-text {
        opacity: min(calc(var(--window-height) * var(--progress-full) / (var(--window-height) * 0.2)), 1);
      }
      p {
        display: none;
      }
      &-card {
        position: relative;
        width: 400px;
        height: 540px;
        border-radius: 20px;
        border: 2px solid transparent;
        background-image: linear-gradient(#000, #000), 
        linear-gradient(300deg, #e657af, #0049ff, #43cf2e);;
        background-origin: border-box;
        background-clip: content-box, border-box;
        .card-text {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          @include font-size(40px);
          text-align: center;
          background: linear-gradient(90deg, #43cf2e ,#0049ff ,#e657af  );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    &--area {
      flex: 0 0 calc(100% - 400px);
      padding: calc(var(--full-height, 100vh) * 1) 0 calc(var(--full-height, 100vh) * 1) 13.5417vw;
    }
  }  

  &__content {
    &--title {
      @include font-size(72px);
      font-weight: 700;
      color: #fff;
    }
    &--description{
      max-width: 750px;
      margin-top: 34px;
      @include font-size(24px);
      font-weight: 700;
      color: #fff;
    }
    &-info {
      margin-top: 80px;
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
        }
        .info-box {
          flex: 0 0.5 400px;
          height: 200px;
          border-radius: 20px;
          border: 2px solid transparent;
          background-image: linear-gradient(#000, #000), 
          linear-gradient(300deg, #e657af, #0049ff, #43cf2e);;
          background-origin: border-box;
          background-clip: content-box, border-box;
          &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 10px;
            @include font-size(28px);
            text-align: center;
            background: linear-gradient(to right, #f00, rgba(0, 0, 0, 0));
            background: linear-gradient(90deg, #43cf2e ,#0049ff ,#e657af  );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .info-title{
          margin-left: 52px;
          @include font-size(24px);
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
  &-slide {
    margin-top: 160px;
    &__title {
      @include font-size(60px);
      color: #fff;
      font-weight: 600;
    }
    &__box {
      margin-top: 80px;
    }
    &__item {
      flex: 0 1 300px;
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
      & > a {
        display: block;
      }
      &-title {
        margin-top: 24px;
        @include font-size(24px);
        font-weight: 700;
        color: #fff;
      }
      img {
        width: 100%;
      }
    }
  }

  &__footer {
    position: relative;
    height: calc(var(--full-height, 100vh) * 2);
    margin-top: calc(var(--full-height, 100vh) * -1);

    &[data-full-step="1"] {
      .service__main--title-card {
        &::before {
    /*       content: '';
          display: block;
          background-image: url(/assets/img/card/card-glow.png);
          background-repeat: no-repeat;
          width: 160%;
          height: 160%;
          left: -30%;
          top: -30%;
          position: absolute;
          background-size: cover; */
          /* animation: card-grow 1s linear forwards paused; */
         /*  opacity: 1; */
   
        }
      }
    }
    .inner {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      left: 0;
      height: calc(var(--full-height, 100vh) * 1);
      padding: 0 100px;
      overflow: hidden;
    }
    .service__main--title-card {
      opacity: 1;

      .card-text  {
        background: #000;
        border-radius: 20px;
      }
      
      &::before {
        content: '';
        display: block;
        background-image: url(/assets/img/card/card-glow.png);
        background-repeat: no-repeat;
        width: 160%;
        height: 160%;
        left: -30%;
        top: -30%;
        position: absolute;
        background-size: cover;
       /*  opacity: 0; */
         animation: card-grow 1s linear forwards paused;
        animation-delay: calc(var(--progress-full) * -1s); 
      }
    }
    &[data-scradar-start="0"][data-scradar-end="1"] {
      .service__main--title-card {
        opacity: 0;
      }
    }
    .left {
      width: 400px;
    }
    p {
      padding-left: 200px;
      font-size: 72px;
      font-weight: 700;
      line-height: 1.28;
      color: #fff;
      opacity: calc(var(--progress-full) * 2.5);
    }
  }
}

@media (min-width: 751px) {
  .slide--service-code {
    $slide: '.slide';
    height: max(calc(var(--full-height, 100vh) * 2 + var(--scroll-width) * 1px), var(--full-height, 100vh));
    &[data-scradar-end="0"] {
      opacity: 0;
    }
    .icon-card-img {
      opacity: calc(1 - (5 *(var(--progress-full) - 0.4)));
    }
    .icon-card-img-active {
      opacity: calc(5 * (var(--progress-full) - 0.6));
    }
    
    @at-root & {
      #{$slide}__inner {
        transform: translate3d(calc( (var(--scroll-width) * 1px + var(--space) * 1px) * (min(var(--progress-full), 0.4) * -2.5) + (var(--body-scroll-width) * 1px + var(--space) * 1px) * ((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5) ), 0, 0);
        .card__item {
          @for $i from 2 through 4 {
            &:nth-child(#{$i}) {
              transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -440px * (#{$i} - 1)), 0, 0);
            }
          }
        }
      }
      &[data-full-step="2"] #{$slide}__inner {
        .icon-card-img-active {
          opacity: calc(1 - ((max(var(--progress-full), 0.8) - 0.8) * 5));
        }
      }
    }
    
    .card__item-box {
      background-color: #000;
    }
  }
  .slide--service {
    $slide: '.slide';
    height: max(calc(var(--full-height, 100vh) * 2 + var(--scroll-width) * 1px), var(--full-height, 100vh));
    margin-top:calc(var(--full-height, 100vh) * -1);
    &[data-scradar-start="0"][data-scradar-end="1"] {
      .card__item {
        &:first-child {
          opacity: 0;
        }
      }
    }
    &[data-scradar-start="1"][data-scradar-end="1"] {
      .card__item {
        &:first-child {
          opacity: 1;
        }
      }
    }
    &[data-scradar-start="1"][data-scradar-end="0"] {
      opacity: 0;
    }
    .card__item-box {
      background-color: #000;
    }

    @at-root & {
      #{$slide}__inner {
        transform: translate3d(calc( (var(--scroll-width) * 1px) * (min(var(--progress-full), 0.4) * -2.5) + (var(--body-scroll-width) * 1px) * ((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5) ), 0, 0);
        .card__item {
          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              z-index: calc(4 - #{$i});
            }
          }
          @for $i from 2 through 4 {
            &:nth-child(#{$i}) {
              transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -440px * (#{$i} - 1)), 0, 0);
            }
          }
        }
      }
    }
    @at-root .slide--service[data-full-step="2"] {
      opacity: 1;
    }
  }
}

@media (max-width: 1440px) {
  .service {
    &__container {
      padding: 0 72px;
      &[data-scradar-start="1"][data-scradar-end="1"] {
        .service__main--title {
          position: fixed;
        }
        .service__main--area {
          margin-left: 303px; 
        }
      }
    }
    &__main {
      &--title {
        width: 303px;
        &-card {
          width: 303px;
          height: 408px;
        }
      }
      &--area {
        padding: calc(var(--full-height, 100vh) * 1) 0 calc(var(--full-height, 100vh) * 1) 8.5417vw;
        flex: 0 0 calc(100% - 303px);
        max-width: calc(100% - 303px);
      }
    }
    &__content {
      &--description{
        margin-top: 24px;
      }
      &-info {
        margin-top: 48px;
        &-item {
          margin-bottom: 36px;
          &:last-child {
            margin-bottom: 0;
          }
          .info-box {
            flex-basis: 340px;
            height: 170px;
          }
          .info-title{
            margin-left: 52px;
          }
        }
      }
    }
    &-slide {
      margin-top: 96px;
      &__box {
        margin-top: 48px;
      }
      &__item {
        flex: 0 1 240px;
        margin-right: 27px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  } 
  
  .slide--service-code {
    $slide: '.slide';
    @at-root & {
      #{$slide}__inner {
        .card__item {
          @for $i from 2 through 4 {
            &:nth-child(#{$i}) {
              transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (#{$i} - 1)), 0, 0);
            }
          }
        }
      }
    }
  }

  .slide--service {
    $slide: '.slide';
    
    #{$slide}__inner {
      .card__item {
        @for $i from 2 through 4 {
          &:nth-child(#{$i}) {
            transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (#{$i} - 1)), 0, 0);
          }
        }
      }
    }
  }

  .service__footer {
    .inner {
      padding: 0 72px;
    }
    .left {
      width: 303px;
    }
    p {
      padding-left: 120px;
      font-size: 54px;
      font-weight: 700;
      line-height: 1.28;
      color: #fff;
      opacity: calc(var(--progress-full) * 2.5);
    }
  }
}
@media (max-width: 1024px) {
  .service {
    &__container {
      padding: 0 60px;
      &[data-scradar-start="1"][data-scradar-end="1"] {
        .service__main--title {
          position: fixed;
        }
        .service__main--area {
          margin-left: 210px; 
        }
      }
    }
    &__main {
      &--title {
        width: 210px;
        .service__main--title {
          width: 210px;
          &-card {
            width: max(calc(210px + (var(--progress-full) - 0.8) * 465px), 210px);
            height: max(calc(284px + (var(--progress-full) - 0.8) * 620px), 284px);
          }
        }
      }
      &--area {
        padding: calc(var(--full-height, 100vh) * 1) 0 calc(var(--full-height, 100vh) * 1) 7.7083vw;
        flex: 0 0 calc(100% - 210px);
        max-width: calc(100% - 210px);
      }
    }
    &__content {
      &--description{
        margin-top: 20px;
      }
      &-info {
        margin-top: 40px;
        &-item {
          display: block;
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 0;
          }
          .info-box {
            flex-basis: 340px;
            max-width: 340px;
            height: 150px;
          }
          .info-title{
            margin: 12px 0 0;
          }
        }
      }
    }
    &-slide {
      margin-top: 80px;
      &__box {
        margin-top: 40px;
      }
      &__item {
        flex: 0 1 158px;
        margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .slide--service-code {
    $slide: '.slide';
    @at-root & {
      #{$slide}__inner {
        transform: translate3d(calc( (var(--scroll-width) * 1px + 120px) * (min(var(--progress-full), 0.4) * -2.5) + (var(--body-scroll-width) * 1px + 120px) * ((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5) ), 0, 0);
        .card__item {
          @for $i from 2 through 4 {
            &:nth-child(#{$i}) {
              transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (#{$i} - 1)), 0, 0);
            }
          }
        }
      }
    }
    @at-root .slide--service-code[data-full-step="2"] {   
      .card__item-box {
        width: calc(303px - (var(--progress-full) - 0.8) * 465px);
        height: calc(408px - (var(--progress-full) - 0.8) * 620px);
      }
    }
  }

  .slide--service {
    $slide: '.slide';
  }

  .service__footer {
    .inner {
      padding: 0 60px;
    }
    p {
      padding-left: 100px;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.28;
      color: #fff;
      opacity: calc(var(--progress-full) * 2.5);
    }
  }
}
@media (max-width: 750px) {
  .service {
    &__container {
      display: block;
      margin-top: calc(var(--full-height, 100vh) * -1.2);
      padding: 0 24px;
      &[data-scradar-start="1"][data-scradar-end="0"] {
        opacity: 1;
      }
      &[data-scradar-start="1"][data-scradar-end="1"] {
        .service__main--title {
          position: relative;
        }
        .service__main--area {
          margin-left: 0; 
        }
      }
    }
    &__footer {
      height: calc(var(--full-height, 100vh) * 5);
      margin-top: calc(var(--full-height, 100vh) * -1.25);
      .inner {
        display: block;
        padding: 12.3153vh 24px 0;
        .left {
          width: 100%;
          opacity: calc((var(--progress-full) - 0.25) * 5);
        }
        p {
          padding: 70px 0 0;
          opacity: calc((var(--progress-full) - 0.1) * 4 - 0.02);
          @media (max-width: 375px) and (min-aspect-ratio: 1/2) {
            padding-top: 28px;
            font-size: 28px;
            line-height: 1.36;
            br {
              display: none;
            }
          }
        }
      }
    }
    .service__main--title {
      display: block;
      position: relative;
      width: 100%;
      height: max(calc(var(--full-height, 100vh) + var(--scroll-width) * 1px), var(--full-height, 100vh));
      margin-top: 0;      
      & > div {
        position: sticky;
        left: 0;
        top: 0;
        width: 100%;
        height: calc(var(--full-height, 100vh) * 1);
      }
      p {
        display: block;
        padding: 13.7931vh 0 36px;
        font-size: 44px;
        line-height: 1.36;
        font-weight: 700;
        @media (max-width: 375px) and (min-aspect-ratio: 1/2) {
          padding: 10.8374vh 0 28px;
          font-size: 28px;
          line-height: 1.36;
          br {
            display: none;
          }
          br ~ br{
            display: inline;
          }
        }
      }
      &-card {
        // width: 100%;
        width: calc(min(var(--window-width), 430) * 1px - 48px);
        height: auto;
        margin: auto;
        transform: translateX(calc((var(--window-width) * 1px / 2 - min(var(--window-width), 430) * 1px / 2) * (1 - min(var(--progress-full), 0.2) * 5)));

        &::before {
          animation: card-grow-mb 1s linear forwards paused;
          animation-delay: calc(var(--progress-full) * -1s); 
          /*   opacity: calc(var(--progress-full) * -1s); */
        }

        & > div {
          position: relative;
          width: 100%;
          height: 0;
          margin: 0 auto;
          padding-top: 132%;
        }
        .card-text {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &__main {
      
      &--area {
        padding: 10.8374vh 0 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }  
  
    &__content {
      &--title {
  
        &-text {
           
        }
      }
  
      &--description{
        margin-top: 24px;
        br {
          display: none;
        }
      }
  
      &-info {
        margin-top: 60px;

        &-item {
          display: block;
          margin-bottom: 36px;
  
          &:last-child {
            margin-bottom: 0;
          }
  
          .info-box {
            max-width: none;
            width: 100%;
            height: 112px;

              &-item {
                padding: 10px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                @include font-size(28px);
                /* line-height: 1.43; */
                text-align: center;
                background: linear-gradient(to right, #f00, rgba(0, 0, 0, 0));
                background: linear-gradient(90deg, #43cf2e ,#0049ff ,#e657af  );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                box-sizing: border-box;
              }
         
          }
  
          .info-title{
           margin-left: 0;
          }
        }
      }

      &-slide {
        margin-top: 60px;

        &-title {
          font-size: 32px;
          color: #fff;
          line-height: 1.02;
          font-weight: 600;
        }
  
        &-box {
          padding: 32px 0 80px;
        }
  
        &-list {
          margin-left: -24px;
          margin-right: -24px;
          padding-left: 24px;
          padding-right: 24px;
          .swiper-wrapper {
           
          }
          .service-slide__item {
            width: 60%;
            &-img {
              &--img {
                img {
                  width: 100%;
                }
              }
            }
            &-title {
              font-size: 16px;
              line-height: 1.5;
              font-weight: 600;
              color: #fff;
              margin-top: 9px;
            }
          }
          
          .swiper__navi {
            margin-top: 19px;
          }

          .slide__btn {
            display: flex;
            justify-content: end;
            margin-top: 32px;

            &--item {
              width: 28px;
              height: 28px;
              margin-left: 16px;
            }
            &-prev {
              background: url(/assets/img/service/icon-prev-on.svg) no-repeat center/cover;

              &.swiper-button-disabled {
                background: url(/assets/img/service/icon-prev.svg) no-repeat center/cover;
              }
            }
  
            &-next {
              background: url(/assets/img/service/icon-next-on.svg) no-repeat center/cover;

              &.swiper-button-disabled {
                background: url(/assets/img/service/icon-next.svg) no-repeat center/cover;
              }
            }
          }
        }
      }
    }
    .service-slide__item {
      flex: 1 0 auto;
    }
  }
  .slide--service-code {
    $slide: '.slide';
    height: calc(var(--full-height, 100vh) * 5);
    &[data-scradar-start="1"][data-scradar-end="0"] {
      opacity: 0;
    }
    .slide__inner {
      transform: none !important;
    }
    @at-root & {
      #{$slide}__item {
        transform: translate3d(calc( (var(--scroll-width) * 1px + 48px) * (min(var(--progress-full), 0.4) * -2.5)), 0, 0);
        .card__item {
          @for $i from 2 through 4 {
            &:nth-child(#{$i}) {
              transform: translate3d(calc((clamp(0.4, var(--progress-full), 0.8) - 0.4) * 2.5 * -327px * (#{$i} - 1)), 0, 0);
            }
          }
        }
      }
    }
    .slide__body {
      .icon-card-img {
        opacity: calc(1 - (5 * (var(--progress-full) - 0.4)));
      }
      .icon-card-img-active {
        opacity: calc(5 * (var(--progress-full) - 0.4));
      }
      @at-root .slide--service-code[data-full-step="1"] .slide__body {   
        opacity: calc(1 - ((max(var(--progress-full), 0.6) - 0.6) * 5));
      }
      @at-root .slide--service-code[data-full-step="2"] .slide__body {
        opacity: 0;        
      }
    }
    .card__item {
      width: calc(min(var(--window-width), 430) * 1px - 48px);
      height: calc((min(var(--window-width), 430) * 1px - 48px) * 1.32);
      padding: 0;
      transform: none !important;
      margin-right: 32px;
      &:last-child {
        margin-right: 0 !important;
      }
      &-box {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .slide--service {
    $slide: '.slide';
    height: calc(var(--full-height, 100vh) * 5);
    .slide__wrap {
      padding-top: 12.3153vh;
      .slide__item {
        margin: 0 auto;
      }
      .slide__inner {
        transform: none;
        .card__item {
          margin-right: 0;
          &:nth-child(2) {
            transform: none;
          }
          @for $i from 3 through 4 {
            &:nth-child(#{$i}) {
              transform: translate3d(0, calc(min(var(--progress-full), 0.4 * (#{$i} - 2)) * 2.5 * ((min(var(--window-width), 430) * -1px + 48px) * 1.32 - 32px)), 0) !important;
            }
          }
          opacity: calc(1 - (max(var(--progress-full), 0.8) - 0.8) * 5);
        }
      }
      @at-root .slide--service[data-full-step="2"] #{$slide}__inner .card__item {
        &:nth-child(2),
        &:nth-child(3) {
          opacity: 0;
        }
      }
    }
  }
}
@media (max-width: 430px) {
  .slide--service-code {
    .slide__body {
      @at-root .slide--service-code[data-full-step="1"] .slide__body {
        .icon-card-img-active {
          transition: opacity 0.5s;
        }        
        opacity: 1;
      }
      @at-root .slide--service-code[data-full-step="2"] .slide__body {
        .icon-card-img-active {
          opacity: 0;
          transition: opacity 0.5s;
        }
        opacity: 1;
      }
    }
  }
}


/* 영문 */
[lang="en"] .service {
  /* mobile */
  @media (max-width: 750px) {
    margin-top: 10.8374vh;

    .service__content--title {
      font-size: 40px;
      line-height: 1.35;
    }
  }
}
@import '../abstracts/mixins';

.ground-section {
  padding:0 100px;

  /* 1440 */
  @media (max-width: 1440px) {
    padding: 0 72px;
  }
  
  /* 1024 */
  @media (max-width: 1024px) {
    padding: 0 60px;
  }
}

.ground {
    &__container {
        padding: 200px 0;
    }
    
    &__content {
      &-title {
        @include font-size(72px);
        font-weight: 600;
        color: #000;
        margin-bottom: 34px;
        &-main {
          display: block;
        }
        &-marquee{
          display: none;
        }
      }

      &-desc {
        @include font-size(24px);
        // line-height: 1.67;
        font-weight: 600;
        color: #000;

        span {
            display: block;
        }
      }
    }

    &__button {
      &-wrap {
        margin-top: 70px;
        display: flex;
      }

      &-item {
        font-size: 18px;
        line-height: 2.22;
        font-weight: 600;
        text-decoration: none;
        border: 2px solid #000;
        padding: 3px 21px 1px;
        display: block;
        margin-right: 18px;

        &:last-child {
          margin-right: 0;
        }
      }

      &-download {
        background: #000;
        color: #fff;
      }

      &-link {
        color: #000;
      }
    }
}

@media (max-width: 750px) { 
  .ground-section {
    padding:0 24px;
  }
  .ground {
      &__container {
          padding: 126px 0 176px;        
      }
      
    &__content {
      &-box {
        overflow: hidden;
        margin-left: -24px;
        margin-right: -24px;
      }
      &-title {
        line-height: 1.36;
        margin-bottom: 24px;

        /* 타이틀 마퀴 */

        &-main {
          display: none;
        }

        &-marquee{
          display: block;
        
          overflow: hidden;

          .title-marquee-inner {
            display: flex; flex-wrap: nowrap; white-space: nowrap; min-width: 100%;
          }

          .title-marquee-item {

          }
          .title-marquee-sign {
            display: inline-block;
            margin-right: 8px;
            font-size: 40px;
          }
        }
      }

      &-desc {
        max-width: 430px;
        font-size: 16px;
        // line-height: 1.63;

        span {
          display: inline-block;
        }
      }
    }

      &__button {
          &-wrap {
              margin-top: 24px;
              flex-wrap: wrap;
          }

          &-item {
              font-size: 14px;
              line-height: 1.71;
              border: 1.2px solid #000;
              padding: 8px 16px;
              margin-right: 6px;
              margin-bottom: 8px;
          }

      }
  }
}

/* 영문 */
[lang="en"] .ground-section {
  /* mobile */
  @media (max-width: 750px) {
    .ground__content-desc span {
      display: inline;
    }
  }
}

/* join */
.join {
  transition: .6s ease-in-out;
  transition-delay: .4s;
  transform: translate(0, 100%);
  &.active {
    transform: translate(0, 0);
  }
  &__content{
    overflow-x: hidden;
    position: relative;
    margin-left: -700px;
    
  
    &-inner {
      display: flex; flex-wrap: nowrap; white-space: nowrap; min-width: 100%;
      
      &-box {
        display: flex;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0px;
          left: 0;
          height: 100%;
          width: 100%;
          backdrop-filter: blur(36px);
        }
      }
    }

    &-list {
      &:nth-child(3n-1) {
        background: #0049ff;
      }
      &:nth-child(3n-2) {
        background:#00bf41;
      }
      &:nth-child(3n) {
        background: #e657af;
      } 
    }
    &-item {
      position: relative;
      display: block;
      color: #fff;
      @include font-size(24px);
      // line-height: 1.67;
      font-weight: 600;
      padding:10px 72px;
      white-space: nowrap;
      z-index: 2;
      
  
      &-italic {
        font-weight: 600;
        font-style: italic;
      }
  
    }
  }
}

@media (max-width: 750px) { 
  .join {
  
    &__content{
      margin-left: -200vw;
      &-item {
        font-size: 16px;
        line-height: 2.5;
        padding:4px 18px;

        &-italic {
          font-weight: 600;
          font-style: italic;
        }
    
      }
    }
  }
}

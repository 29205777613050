@import '../abstracts/mixins';

/* card 공통 */
.card {
  &__content {
    .service__main--title-card {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-mask {
      display: none;
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
  }
  &__item {
    position: relative;
    width: 400px;
    height: 540px;
    margin-right: 40px;
    flex-shrink: 0;
    &:last-child {
      margin-right: 0;
    }
    &-head-caption {
      height: 100%;
      width: 50%;
    }
    .caption-desc {
      // display: none;
      @include font-size(16px);
      position: absolute;
      bottom: 10px;
      right: 0;
      opacity: 0;
      transition: opacity 0.5s;
      @media (max-width: 1025px) {
        bottom: 0;
      }
    } 
    &-box {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      transform: translate(0, -50%);
      padding: 34px;
      color: #fff;
      border-radius: 20px;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        transition: 0.5s;
      }
      .card__item-info-main-text {
        @media (min-width: 751px) {
          font-size: 16px;
        }
      }
      &-line {
        padding: 0;
        .service__main--title-card {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
        }
      }
      &-icon {
        position: relative;
        height: 100%;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 150px;
        }
      }
    }

    &-head  {
      position: relative;
      min-height: 110px;

      &.no-caption {
        .card__item-head-title {
          margin-right: 0;
        }
      }

      &-title {
        @include font-size(50px);
        font-weight: 600;
        margin-right: 46px;
        color: #fff;
        word-break: break-word;
        line-height: 1 !important;
        
      }
      &-caption {
        position: absolute;
        right: 0;
        top: 12px;
        font-size: 30px;
        //font-weight: 500;
        line-height: 0.67;
        color: #fff;
        text-align: right;
        font-family: 'Archivo';
      }
    }
    &-head2  {
      min-height: auto;

      &-title {
        line-height: 1.24;
      }
    }


    &-contents{
      position: relative;
      opacity: 0;
      transition: opacity 0.5s;
      &-code {
        padding-top: 56px;
        padding-right: 50px;
        img {
          width: 100%;
          // height: 209px;
        }
      }
      &-text {
        padding-top: 40px;
        @include font-size(16px);
        line-height: 1.63;
        font-weight: 600;

        p {
          margin: 0;
          margin-top: 24px;

          &+p {
            margin-top: 8px;
          }
        }
      }

      &-list {
        padding-top: 40px;

        .item-contents__inner {
          &-list {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          &-item {
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }
            &-title {
              @include font-size(20px);
              font-weight: 600;
              margin: 0 0 3px;
            }
            &-text {
              @include font-size(16px);
              font-weight: 400;
            }
          }
        }
      }
    }

    &-info{
      position: absolute;
      left: 34px;
      right: 34px;
      bottom: 34px;
      margin-top: auto;
      padding-right: 40px;
        &-main{
          &-profile  {
            display: flex;
            align-items: center;
            opacity: 1;
            transition: opacity 0.5s;

            .profile-img {
              position: relative;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              background: #fff;
              overflow: hidden;

              img {
                position: absolute;
              }
            }

            .profile-name {
              display: inline-block;
              @include font-size(16px);
              font-weight: 700;
              margin-left: 9px;
            }

            .profile-bg {
              display: block;
              width: 100%;
              height: 100%;
              background: #26ff00;

              &.type-1 {
                background: #ff009d;
              }
              &.type-2 {
                background: #0049ff;
              }
              &.type-3 {

              }

            }
          }

          &-title {
            @include font-size(24px);
            opacity: 0;
            transition: opacity 0.5s;
          }

          &-text {
            @include font-size(16px);
            line-height: 1.63;
          }
        }

      &-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        display: block;
        background: url(/assets/img/card/expand-icon.png) no-repeat center/cover;
        
      }
    }
    &-blur {
      .card__item-box-img-clone {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: url(#sharpBlur);
        z-index: -1;
        opacity: 0;
        border-radius: 20px;
        transition: opacity 0.5s;
      }
      .card__item-box {
        &::before {
          opacity: 0;
          transition: opacity 0.5s;
          background-blend-mode: multiply;
          background-color: rgba(106, 106, 106, 0.3);
        }
      }
    }
  }
}

.slide--service-code{
  .card__item-info-main-profile {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 1440px) {
  .card {
    &__list {
      &_v3 {
        .card__item-head {
          min-height: auto;
        }
      }
    }
    &__item {
      width: 303px;
      height: 408px;
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
      &-box {
        padding: 24px;
      }
      &-info {
        left: 24px;
        right: 24px;
        bottom: 24px;
      }
      &-head {
        min-height: 72px;
      }
      &-contents {
        &-text {
          padding-top: 24px;
          p {
            margin-top: 16px;
            & + p {
              margin-top: 4px;
            }
          }
        }
        &-code {
          padding: 28px 32px 0 0;
        }
        &-list {
          padding-top: 32px;
          .item-contents__inner {
            &-item {
              margin-bottom: 24px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

/* mobile */
@media (max-width: 750px) {
  .slide__wrap {
    .slide__description {
      br {
        display: none;
      }
    }
  }
}

/* 영문 */
[lang="en"] {
  .item-contents__inner-item-text{
    font-family: 'Archivo';
  }

  .card__item-head-caption {
    .caption-desc {
      bottom: 20px;
      font-size: 14px;
    }
  }

  /* pc */
  @media (min-width: 751px) {
    .card__item-contents-list {
      padding-top: 38px;
      // .item-contents__inner-item {
      //   margin-bottom: 28px;
      // }
      .item-contents__inner-item-title {
        margin: 0 0 6px;
      }
    }

    /* asset */
    .slide--asset {
      .card__item:first-child {
        .card__item-contents-list {
          .item-contents__inner-item {
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  @media (min-width: 751px) and (max-width: 1440px) {
    .card__item-contents-list {
      padding-top: 20px;
    }

    .card__item-info-main-text {
      font-size: 14px;
    }
  }

  /* mobile */
  @media (max-width: 750px) {
    .card__item-box {
      // padding: 28px 28px 23px;
    }
    .card__item-head-title {
      line-height: 1.1 !important;
    }
    .card__item-info-icon {
      width: 32px;
      height: 32px;
      margin-bottom: 5px;
    }
    .card__item-contents-list {
      padding-top: 29px;
      .item-contents__inner-item {
        margin-bottom: 25px;
      }
    }

    .card__item-head-caption {
      right: 2px;
      top: 9px;      
      .caption-desc {
        bottom: 12px;
        font-size: 12px;
      }
    }

    .slide--asset {
      .card__item-head-title {
        margin-right: 85px;
      }
      .slide__description {
        br {
          display: none;
        }
      }
      .card__item:first-child {
        .card__item-contents-list {
          padding-top: 19px;
          .item-contents__inner-item-title {
            margin: 0 0 3px;
          }
          .item-contents__inner-item {
            margin-bottom: 12px;
          }
          .item-contents__inner-item-text{
            line-height: 1.25;
          }

        }
      }
    }
  }
}

/* card 배경 */
.card__list_v2 {
  .card__item {
    &:nth-child(2) {
      .profile-bg {
        background: #ff009d;
      }
    }
    &:nth-child(3) {
      .profile-bg {
        background: #0049ff;
      }
    }
    &:nth-child(4) {
      .profile-bg {
        background: #26ff00;
      }
    }
  }
  .item-bg-1 {
    background: url(/assets/img/card/image-sec-2-1.png) no-repeat top/cover;
  }
  .item-bg-2 {
    background: url(/assets/img/card/image-sec-2-2.png) no-repeat top/cover;
  }
  .item-bg-3 {
    background: url(/assets/img/card/image-sec-2-3.png) no-repeat top/cover;
  }
}
.card__list_v3 {
  .item-bg-1 {
   background:  #e657af;
  }
  .item-bg-2 {
    background:#0049ff;
  }
  .item-bg-3 {
    background: url(/assets/img/card/image-sec-3-3.png) no-repeat top/cover;
  }
}
.card__list_v4 {
  .item-bg-1 {
    background: url(/assets/img/card/image-sec-4-1.png) no-repeat top/cover;
  }
  .item-bg-2 {
    background:#0049ff;
  }
  .item-bg-3 {
    background: #131415;
  }
  .item-bg-4 {
    background: #00bf41;
  }
}


/* click 효과 */
.card__item {
  &-active,
  &--not-event {
    .card__item {
      &-contents{
        opacity: 1;
      }
      &-info {
        &-main {
          &-active {
            .card__item-info-main-profile {
              // display: none;
              opacity: 0;
            }
            .card__item-info-main-title {
              // display: block;
              opacity: 1; 
            }
          }
        }

        &-icon {
          background: url(/assets/img/card/contract-icon.png) no-repeat center/cover; 
        }
      }
      &-info{
        &-main{
          // &-profile {
          //   opacity: 0;
          // }
          &-title {
            opacity: 0;
          }
        }
      }
    }

    .caption-desc {
      // display: block;
      opacity: 1;
    }

    &.card__item-blur {
      .card__item-box-img-clone {
        opacity: 1;
      }

      .card__item-box {
        &::before {
          opacity: 1;
        }
      }
    }
  }


}

/*  mobile */
@media (max-width: 750px) {
  /* card 공통 */
  .card{
    &__content {
      text-align: center;
    }
    &__item {
      @at-root .card .card__list {
        &_v2, &_v3, &_v4 {
          width: 100%;
        }
      }
      // width: 100%;
      // height: 0;
      // margin: 0;
      // padding-top: 132%;
      width: calc(min(var(--window-width), 430) * 1px - 48px);
      height: calc((min(var(--window-width), 430) * 1px - 48px) * 1.32);
      &:last-child {
        margin-right: 0;
      }

      &-box {
        padding: 26px;
        text-align: left;

        @media (max-width: 360px) {
          padding: 15px;
        }

        &-icon {

          img {
            width: 98px;
          }
        }
      }

      &-head  {
        min-height: 88px;

        &-title {
          font-size: 40px;
        }
        &-caption {
          right: 3px;
          top: 9px;
          font-size: 20px;
          line-height: 1;

          .caption-desc{
            bottom: 4px;
          }
        }
      }
      &-head2  {
        min-height: auto;

        &-title {
          line-height: 1.24;
        }
      }


      &-contents{
        &-code {
          padding-top: 27px;
          padding-right: 0;

          // img {
          //   height: 209px;
          // }
        }
        &-text {
          padding-top: 30px;
          @at-root {
            [lang="en"] { 
              .card__item-contents-text {
                padding-top: 20px;
                font-size: 14px;
              }
            }
          }

          br {
            display: none;
          }

          p {
            font-size: 14px;
            line-height: 1.71;
            &+p {
              margin-top: 4px;
            }
          }
        }

        &-list {
          padding-top: 28px;

          .item-contents__inner {
              &-item {
                margin-bottom: 20px;

                &-text {
                  font-size: 16px;
                }
              }
          }
        }
      }

      &-info{
        left: 26px;
        right: 26px;
        bottom: 26px;
        @media (max-width: 360px) {
          left: 15px;
          right: 15px;
          bottom: 15px;
        }
        // margin-top: auto;
        // position: relative;
        padding-right: 40px;
          &-main{
            &-profile  {
              opacity: 1;
              transition: opacity 0.5s;
              .profile-img {
                width: 30px;
                height: 30px;

              }

              .profile-name {
                margin-left: 8px;
              }
            }

            &-title {
              opacity: 0;
              transition: opacity 0.5s;
              font-size: 24px;
              font-weight: 600;
            }

            &-text {
              font-size: 16px;
              font-weight: 600;
              line-height: 1.63;
              transform: translateY(-3px);
            }
          }

        &-icon {
          width: 34px;
          height: 34px;
        }
      }
    }
    &__list {
      &_v1 {
      }
      &_v2 {
        display: block;
        .card__item {
          margin-bottom: 32px;
          &:last-child {
            margin-bottom: 0px;
          }
          &:first-child {
            display: none;
          }
        }
      } 
      &_v3 {
        display: block;
        .card__item {
          & + .card__item {
            margin-top: 32px;
          }
        }
      }
      &_v4 {
        
      }
    }
  }
}


 
.card__list_v1 {
  .card__item-box {
    border: solid 2px #fff;

    &.line-color {
      border: 2px solid transparent;
      padding: 0;
      background-image: linear-gradient(#000, #000), 
      linear-gradient(315deg, #e657af, #0049ff, #43cf2e);;
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }
}


